import React, {useEffect, useState} from 'react';
import css from './basic-data.module.less';
import {
    Anchor,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table,
    TreeSelect
} from 'antd';
import {useDataApi, useUserInfo} from "../../../hook";
import {AddressItem, UploadImage} from "../../../components";
import {resourcesUrl} from "../../../common/static_number";
import classNames from 'classnames';
import {ExpertModal} from "../../../common";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {store} from "../../../helps";

const {Option} = Select;

const {TextArea} = Input;
const {Link} = Anchor;
const { SHOW_ALL } = TreeSelect;
export const BasicData  = (props)=>{
    const {push} = useHistory();
    const {userInfo,updateInfo} = useUserInfo();
    const {type ,data} = props;
    const [valueMap] = useState({});
    const [industryList, setIndustryList] = useState([]);
    const [modalFlag,setModalFlag] = useState('');
    const [modalData,setModalData] = useState({});
    const [initData,setInitData] = useState({});
    const [form] = Form.useForm();
    const [baseForm] = Form.useForm();
    const [educationList,setEducationList] = useState([])
    const [jobList,setJobList] = useState([])
    const [professionalList,setProfessionalList] = useState([]);
    const [avoidList,setAvoidList] = useState([]);
    const [qualificationList,setQualificationList] = useState([]);
    const [otherList,setOtherList] = useState([]);
    const [{data:categroyList}] = useDataApi({url: '/api/getAddCategroy', method: 'post', data: { project_type: 1, type: 1 },initialData: [] });
    const [{data:professionList}] = useDataApi({url: '/api/gettitles', method: 'post',initialData:[]});

    useEffect(()=>{
        form.resetFields();
    },[modalFlag]);
    useEffect(()=>{
        let _data = {};
        if(_data.status !== 0){
            const { industry, bidprovince_id, bidcity_id, bidcounty_id, province_id, city_id, county_id,} = data;
            _data = {...data};
            _data.birthday = data.birthday? moment(data.birthday ) :null;
            _data.region = data.province_id ? [province_id,city_id,county_id]:void 0;
            _data.address = data.bidprovince_id ? [bidprovince_id,bidcity_id,bidcounty_id]:void 0;
            _data.industry = industry.length === 0 ? undefined : industry.map((item)=>{
                return parseInt(item.industry_id)
            });
            console.log("_data.industry",_data.industry);
             setEducationList(data.expert_education);
            setJobList(data.expert_work);
            setProfessionalList(data.expert_title);
            setQualificationList(data.expert_practice);
            setAvoidList(data.expert_back);
            setOtherList(data.expert_other);
        }
        _data.isLoading = true;
        setInitData({..._data})
    },[data]);
    const educationColumns = [
        {
            title: '最高学历',
            dataIndex: 'highest',
            key: 'highest',
            align: 'center',
        },
        {
            title: '毕业学校',
            dataIndex: 'school',
            key: 'school',
            align: 'center',
        },
        {
            title: '主修专业',
            dataIndex: 'major',
            key: 'major',
            align: 'center',
        },
        {
            title: '毕业时间',
            dataIndex: 'endtime',
            key: 'endtime',
            align: 'center',
        },
        {
            title: '学历证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('education',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('education',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>

                    </div>
                )

            }
        },

    ];
    const jobColumns = [
        {
            title: '单位名称',
            dataIndex: 'company',
            key: 'company',
            align: 'center',
        },
        {
            title: '工作内容',
            dataIndex: 'content',
            key: 'content',
            align: 'center',

        },
        {
            title: '开始时间',
            dataIndex: 'starttime',
            key: 'starttime',
            align: 'center',

        },
        {
            title: '结束时间',
            dataIndex: 'endtime',
            key: 'endtime',
            align: 'center',

        },
        {
            title: '工作状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',

            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        {{1:'在职',2:'离职',3:'兼职',4:'退休'}[text]}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('job',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('job',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>
                    </div>
                )

            }
        },

    ];
    const professionalColumns = [
        {
            title: '职称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, record,index) =>{
             const item =    professionList.filter(item=>{
                    return item.id === record.title_id

                })
                console.log("title_id",item)
                return item[0] && item[0].name;
            }
        },
        {
            title: '发证单位',
            dataIndex: 'certification_unit',
            key: 'certification_unit',
            align: 'center',
        },
        {
            title: '证书编号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
        },
        {
            title: '发证时间',
            dataIndex: 'issuing_time',
            key: 'issuing_time',
            align: 'center',
        },
        {
            title: '证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('professional',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('professional',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>
                    </div>
                )

            }
        },

    ];
    const qualificationColumns = [
        {
            title: '执业资格',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '发证单位',
            dataIndex: 'certification_unit',
            key: 'certification_unit',
            align: 'center',

        },
        {
            title: '证书编号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
        },
        {
            title: '发证时间',
            dataIndex: 'issuing_time',
            key: 'issuing_time',
            align: 'center',

        },
        {
            title: '证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',

            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('qualification',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('qualification',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>
                    </div>
                )

            }
        },

    ];
    const avoidColumns = [
        {
            title: '单位名称',
            dataIndex: 'store_name',
            key: 'store_name',
            align: 'center',
            width: 300,

        },
        {
            title: '原因',
            dataIndex: 'remark',
            key: 'remark',

            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('avoid',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('avoid',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>
                    </div>
                )

            }
        },


    ];
    const otherColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '说明',
            dataIndex: 'remark',
            key: 'remark',
            width: 300,
            align: 'center',
        },
        {
            title: '其他材料扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('other',{index,...record})}>编辑</span>
                        <Popconfirm title="确定要删除本条数据嘛?" onConfirm={() => deleteList('other',index)}>
                            <span className={css.del}>删除</span>
                        </Popconfirm>
                    </div>
                )

            }
        },

    ];

    const showModal = (key,data,index)=>{
        if(data){
            switch (key) {
                case 'education':
                   data.endtime = data.endtime? moment(data.endtime) : null;

                    break;
                case 'job':
                    data.starttime = data.starttime? moment(data.starttime) : null;
                    data.endtime = data.endtime? moment(data.endtime) : null;
                    break;
                case 'professional':
                    data.issuing_time = data.issuing_time? moment(data.issuing_time) : null;
                    break;
                case 'qualification':
                    data.issuing_time = data.issuing_time? moment(data.issuing_time) : null;
                    break;
                case 'avoid':
                    break;
                case 'other':
                    break;
                default:
            }
            setModalData({index,...data});
        }else{
            setModalData({});
            console.log("modalData",modalData);
        }
        setModalFlag(key);

    }
    const submitModal = (v)=>{
        form.resetFields();
        switch (modalFlag) {
            case 'education': //学历信息
                const _educationList = educationList;
                v.endtime =  v.endtime ? v.endtime.format('YYYY-MM-DD') : '';
                if(modalData.index === undefined){
                    _educationList.push({...v});
                }else{
                    _educationList[modalData.index] = v;
                }
                setEducationList([..._educationList]);
                break;
            case 'job':
                const _jobList = jobList;
                v.starttime =  v.starttime ? v.starttime.format('YYYY-MM-DD') : '';
                v.endtime =  v.endtime ? v.endtime.format('YYYY-MM-DD') : '';
                if(modalData.index === undefined){
                    _jobList.push({...v});
                }else{
                    _jobList[modalData.index] = v;
                }
                setJobList([..._jobList]);
                break
            case 'professional':
                const _professionalList = professionalList;
                v.issuing_time =  v.issuing_time ? v.issuing_time.format('YYYY-MM-DD') : '';
                if(modalData.index === undefined){
                    _professionalList.push({...v});
                }else{
                    _professionalList[modalData.index] = v;
                }
                setProfessionalList([..._professionalList]);
                break
            case 'qualification': //职业资格
                const _qualificationList = qualificationList;
                v.issuing_time =  v.issuing_time ? v.issuing_time.format('YYYY-MM-DD') : '';
                if(modalData.index === undefined){
                    _qualificationList.push({...v});
                }else{
                    _qualificationList[modalData.index] = v;
                }
                setQualificationList([..._qualificationList]);
                break
            case 'avoid': //回避信息
                const _avoidList = avoidList;
                if(modalData.index === undefined){
                    _avoidList.push({...v});
                }else{
                    _avoidList[modalData.index] = v;
                }
                setAvoidList([..._avoidList]);
                break
            case 'other': //其他材料
                const _otherList = otherList;
                if(modalData.index === undefined){
                    _otherList.push({...v});
                }else{
                    _otherList[modalData.index] = v;
                }
                setOtherList([..._otherList]);
                break
            default:
        }
        setModalFlag('');
    }
    function loops(list, parent) {
        return (list || []).map(({ children, value }) => {
            const node = (valueMap[value] = {
                parent,
                value
            });
            node.children = loops(children, node);
            return node;
        });
    }
    function getPath(value) {
        const path = [];
        let current = valueMap[value];
        while (current) {
            path.unshift(current.value);
            current = current.parent;
        }
        return path;
    }
    useEffect(() => {
        if (categroyList.length) {
            const _industry = categroyList.map(item => {
                if (item.son.length) {
                    const children = item.son.map(child => {
                        return {title: child.name, value: child.value,}
                    })
                    return {title: item.name, value: item.value, children, disabled: true,}
                } else {
                    return {title: item.name, value: item.value}
                }
            });
            setIndustryList(_industry);
            loops(_industry);
        }
    }, [categroyList]);

    const onSubmit = (value) => {

        if(!educationList.length){
            message.error('您还没有填写学历信息呦~');
            return
        }
        if(!jobList.length){
            message.error('您还没有填写工作履历呦~');
            return
        }
        if(!professionalList.length){
            message.error('您还没有填写职称信息呦~');
            return
        }
        const dataForm = formatData();

        ExpertModal.updateInfo({type,is_approval:1,...dataForm}).then((res) => {
                const {data, code, msg} = res.data;
                if (code === 200) {
                    store.remove('userInfo');
                    push('/expert/reg/2/'+type);
                } else {
                    message.error(msg);
                }
        })
    };
    const saveData = ()=>{
        const dataForm = formatData();

        ExpertModal.updateInfo({type,is_approval:0,...dataForm}).then((res) => {
            console.log("res", res);
            const {data, code, msg} = res.data;
            if (code === 200) {
                message.success('保存成功！',3);
                // updateParams({url: '/api/expertInfo', method: 'post', initialData: []});
            } else {
                message.error(msg);
            }
        })
    };
    const formatData = (data)=>{
        const _data = baseForm.getFieldsValue();
        const [bidprovince_id,bidcity_id,bidcounty_id] = _data.address || [];
        const [province_id,city_id,county_id] = _data.region || [];
        _data.birthday = _data.birthday && _data.birthday.format("YYYY-MM-DD") ;
        delete _data.address;
        delete _data.region;
        let industry = [];
        const industry_type=[];
        const industry_id=[];

        if(_data.industry){
            _data.industry.map((item,index)=>{
                const [type,id] =  getPath(item);
                industry_type.push(type);
                industry_id.push(id);
                industry.push({industry_type:type,industry_id:id});
            });
        }
        _data.industry = industry;
        return {type,
            bidprovince_id,
            bidcity_id,
            bidcounty_id,
            province_id,
            city_id,
            county_id,
            industry_type:industry_type.join(','),
            industry_id:industry_id.join(','),
            expert_education:educationList,
            expert_work:jobList,
            expert_title:professionalList,
            expert_practice:qualificationList,
            expert_back:avoidList,
            expert_other:otherList,
            ..._data};

    }
    const deleteList = (key,index)=>{
        let _list = [];
            switch (key) {
            case 'education':
                _list = educationList;
                _list.splice(index,1);
                setEducationList([..._list]);
                break;
            case 'job':
                _list = jobList;
                _list.splice(index,1);
                setJobList([..._list]);
                break;
            case 'professional':
                _list = professionalList;
                _list.splice(index,1);
                setProfessionalList([..._list]);
                break;
            case 'qualification':
                _list = qualificationList;
                _list.splice(index,1);
                setQualificationList([..._list]);
                break;
            case 'avoid':
                _list = avoidList;
                _list.splice(index,1);
                setAvoidList([..._list]);
                break;
            case 'other':
                _list = otherList;
                _list.splice(index,1);
                setOtherList([..._list]);
                break;
            default:
        }
    }
    return (
        <div className={ css.basicData }>
            <Anchor targetOffset={30}>
                <Link href="#basic" title="基本信息（必填）"/>
                {type == 3 && <Link href="#evaluation" title="评审信息（必填）"/>}
                <Link href="#education" title="学历信息（必填）"/>
                <Link href="#job" title="工作履历（必填）"/>
                <Link href="#professional" title="职称信息（必填）"/>
                <Link href="#qualification" title="职业资格（选填）"/>
                {type == 3 &&  <Link href="#avoid" title="回避信息（选填）"/>}
                <Link href="#other" title="其他材料（选填）"/>
            </Anchor>
            {
                initData.isLoading &&  <Form form={baseForm} onFinish={onSubmit} scrollToFirstError initialValues={initData} >
                    <div id={'basic'} className={css.boxWarp}>
                        <div className={css.warpTitle}>基本信息（必填）</div>
                        <div className={css.warpContent}>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'姓名'} name={'username'}
                                               rules={[{required: true, message: '请输入姓名'}]}>
                                        <Input placeholder={'请输入姓名'}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'个人照片'} name="images"
                                               rules={[{required: true, message: '请上传个人照片'}]}
                                               wrapperCol={{span: 10}}>
                                        <UploadImage uploadNum={1} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'性别'} name={'gender'}
                                               rules={[{required: true, message: '请选择性别'}]}>
                                        <Radio.Group>
                                            <Radio value={0}>男</Radio>
                                            <Radio value={1}>女</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'出生日期'} name="birthday" rules={[{
                                        required: true,
                                        message: '请输入出生日期'
                                    }]}>
                                        <DatePicker format="YYYY-MM-DD"/>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'身份证号码'} name="legal_identity" rules={[{
                                        required: true,
                                        message: '请输入身份证号码'
                                    }, {type: 'string', len: 18, message: '身份证号码不正确'}]}>
                                        <Input placeholder="请输入身份证号码"/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'身份证扫描件'} name="legal_identity_cert"
                                               rules={[{required: true, message: '请上传身份证扫描件'}]}
                                    extra={'单张最大6M,最多上传2张（身份证正反面），支持：JPG / JPEG / GIF / PNG 格式'}>
                                        <UploadImage uploadNum={2} extra={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'政治面貌'} name="political" rules={[{
                                        required: true,
                                        message: '请选择政治面貌'
                                    }]}>
                                        <Select  placeholder="请选择政治面貌" >
                                            <Option value="中国共产党党员">中国共产党党员</Option>
                                            <Option value="中国共产党预备党员">中国共产党预备党员</Option>
                                            <Option value="中国共产党青年团团员">中国共产党青年团团员</Option>
                                            <Option value="群众">群众</Option>
                                            <Option value="其他">其他</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'常驻地址'} name="region" rules={[{
                                        required: true,
                                        message: '请输入常驻地址'
                                    }]}>
                                        <AddressItem size="default" />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'手机号码'} name="mobile" rules={[{
                                        required: true,
                                        message: '请输入手机号码'
                                    }, {type: 'string', len: 11, message: '手机号码不正确'}]}>
                                        <Input placeholder="请输入政治面貌" disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'电子邮箱'} name="email"    rules={[{
                                        type: 'email',
                                        message: '邮箱格式不正确',
                                    }]}>
                                        <Input placeholder="请输入电子邮箱"/>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'邮政编码'} name="postal_ode" >
                                        <Input placeholder="请输入邮政编码"/>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {
                        type == 3 && <div id={'evaluation'} className={css.boxWarp}>
                            <div className={css.warpTitle}>评审信息（必填）</div>
                            <div className={css.warpContent}>
                                <Row className={css.row}>
                                    <Col span={12} className={css.col}>
                                        <Form.Item label={'意愿评标地区'}
                                                   name="address" rules={[{
                                            required: true,
                                            message: '请输入意愿评标地区'
                                        }]}>
                                            <AddressItem size="default" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className={css.col}>
                                        <Form.Item label={'工作年限'}
                                                   name="major_age" rules={[{
                                            required: true,
                                            message: '请输入工作年限'
                                        }]}>
                                            <Input placeholder="请输入工作年限" addonAfter={'年'}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className={css.row}>
                                    <Col span={24} className={css.col}>

                                        <Form.Item label={'评标行业'} name="industry"
                                                   rules={[{required: true, message: '请选择评标行业'}]}>
                                            <TreeSelect
                                                multiple
                                                showCheckedStrategy= {SHOW_ALL}
                                                style={{width: '100%'}}
                                                defaultValue={initData.industry}
                                                treeData={industryList}
                                                placeholder="请选择评标行业"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }


                    <div id={'education'} className={css.boxWarp}>
                        <div className={css.warpTitle}>学历信息（必填）
                            <div className={css.addBtn} onClick={()=>showModal('education')} >新增</div>
                        </div>
                        <Table dataSource={educationList} columns={educationColumns} pagination={false} bordered/>
                    </div>

                    <div id={'job'} className={css.boxWarp}>
                        <div className={css.warpTitle}>工作履历（必填）
                            <div className={css.addBtn} onClick={()=>showModal('job')}>新增</div>
                        </div>
                        <Table dataSource={jobList} columns={jobColumns}  pagination={false} bordered/>
                    </div>

                    <div id={'professional'} className={css.boxWarp}>
                        <div className={css.warpTitle}>职称信息（必填）
                            <div className={css.addBtn} onClick={()=>showModal('professional')}>新增</div>
                        </div>
                        <Table dataSource={professionalList} columns={professionalColumns}  pagination={false} bordered/>
                    </div>

                    <div id={'qualification'} className={css.boxWarp}>
                        <div className={css.warpTitle}>职业资格（选填）
                            <div className={css.addBtn} onClick={()=>showModal('qualification')}>新增</div>
                        </div>
                        <Table dataSource={qualificationList} columns={qualificationColumns}  pagination={false} bordered/>
                    </div>

                    {type == 3 && <div id={'avoid'} className={css.boxWarp}>
                        <div className={css.warpTitle}>回避信息（选填）
                                <div className={css.addBtn} onClick={() => showModal('avoid')}>新增</div>
                            </div>
                            <Table dataSource={avoidList} columns={avoidColumns} pagination={false} bordered/>
                        </div>
                    }
                    <div id={'other'} className={css.boxWarp}>
                        <div className={css.warpTitle}>其他材料（选填）
                            <div className={css.addBtn} onClick={()=>showModal('other')}>新增</div>
                        </div>
                        <Table dataSource={otherList} columns={otherColumns}  pagination={false} bordered/>
                    </div>

                    <div className={css.btns}>
                        {initData.status === 0 && <div className={classNames(css.btn,css.prePage)}>上一步</div>}
                        {initData.status === 0 && <div className={classNames(css.btn,css.saveBtn)} onClick={(e)=>saveData()}>保存草稿</div>}
                        <div  className={css.btn}  onClick={(e)=>baseForm.submit()}>提交申请</div>
                    </div>
                </Form>
            }

            <Modal  title="学历信息"
                    visible={ modalFlag === 'education' }
                    centered
                    footer={null}
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item
                        label="最高学历"
                        name="highest"
                        rules={[{required:true,message:'请选择最高学历'}]}
                    >
                        <Select  placeholder="请选择最高学历" >
                            <Option value="博士后">博士后</Option>
                            <Option value="博士">博士</Option>
                            <Option value="硕士">硕士</Option>
                            <Option value="本科">本科</Option>
                            <Option value="大专">大专</Option>
                            <Option value="高中/中专">高中/中专</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='毕业时间' name={'endtime'} rules={[{required:true,message:'请选择毕业时间'}]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label='毕业院校' name={'school'}  rules={[{required: true, message: '请填写毕业院校'}]}>
                        <Input type="text" autoComplete="off" placeholder="请输入毕业院校"/>
                    </Form.Item>
                    <Form.Item label={'主修专业'}  name="major" rules={[{required:true,message:'请输入主修专业'}]}>
                        <Input placeholder="请输入主修专业" />
                    </Form.Item>
                    <Form.Item label={'学历证书扫描件'} name="certificate" rules={[{required:true,message:'请上传学历证书'}]}>
                        <UploadImage uploadNum={5} />
                    </Form.Item>

                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>

            <Modal  title="工作履历"
                    visible={ modalFlag === 'job' }
                    centered
                    footer={null}
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item
                        label="单位名称"
                        name="company"
                        rules={[{required:true,message:'请输入您参加工作的单位名称'}]}
                    >
                        <Input type="text" autoComplete="off" placeholder="请输入您参加工作的单位名称" />
                    </Form.Item>
                    <Form.Item
                        label="工作状态"
                        name="status"
                        rules={[{required:true,message:'请选择工作状态'}]}
                    >
                        <Select  placeholder="请选择工作状态" >
                            <Option value="1">在职</Option>
                            <Option value="2">离职</Option>
                            <Option value="3">兼职</Option>
                            <Option value="4">退休</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='工作开始时间' name={'starttime'} rules={[{required: true, message: '请填写工作开始时间'}]} >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label='工作结束时间' name={'endtime'} rules={[{required: true, message: '请填写工作结束时间'}]} >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    <Form.Item label='单位地址' name={'address'}  rules={[{required: true, message: '请填写单位地址'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写单位地址"/>
                    </Form.Item>
                    <Form.Item label={'所属行业'}  name="industry" rules={[{required:true,message:'请选择所属行业'}]}>
                        <Select  placeholder="请选择所属行业" >
                            <Option value="国家机关">国家机关</Option>
                            <Option value="事业单位">事业单位</Option>
                            <Option value="大专院校">大专院校</Option>
                            <Option value="医院科研机构">医院科研机构</Option>
                            <Option value="学会/协会/社团">学会/协会/社团</Option>
                            <Option value="企业">企业</Option>
                            <Option value="中介机构">中介机构</Option>
                            <Option value="其他">其他</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={'单位级别'}  name="level">
                        <Select  placeholder="请选择单位级别" >
                            <Option value="中央">中央</Option>
                            <Option value="省级">省级</Option>
                            <Option value="市级">市级</Option>
                            <Option value="县（区）级">县（区）级</Option>
                            <Option value="乡镇级">乡镇级</Option>
                            <Option value="其他">其他</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={'工作内容'}  name="content" >
                        <TextArea placeholder={'请输入工作内容'}/>
                    </Form.Item>
                    <Form.Item label={'工作证明扫描件'}  name="certificate">
                        <UploadImage uploadNum={10} />
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="职称信息"
                    visible={ modalFlag === 'professional' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item
                        label="职称"
                        name="title_id"
                        rules={[{required:true,message:'请选择职称'}]}
                    >
                        <Select  placeholder="请选择职称" >
                            {professionList.map((item,index)=> <Option value={item.id} key={item.id} >{item.name}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item label='证书编号' name={'number'} rules={[{required: true, message: '请填写证书编号'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写证书编号"/>
                    </Form.Item>
                    <Form.Item label='发证单位' name={'certification_unit'}  rules={[{required: true, message: '请填写发证单位'}]}>
                        <Input type="text" autoComplete="off"  placeholder="请填写发证单位"/>
                    </Form.Item>
                    <Form.Item label={'发证时间'}  name="issuing_time" rules={[{required:true,message:'请选择发证时间'}]}>
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    <Form.Item label={'证书扫描件'}  name="certificate" rules={[{required:true,message:'请上传证书扫描件'}]}>
                        <UploadImage uploadNum={5} />
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="职业资格"
                    visible={ modalFlag === 'qualification' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item label='职业资格' name={'name'} rules={[{required: true, message: '请填写职业资格'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写职业资格"/>
                    </Form.Item>
                    <Form.Item label='证书编号' name={'number'} rules={[{required: true, message: '请填写证书编号'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写证书编号"/>
                    </Form.Item>
                    <Form.Item label='发证单位' name={'certification_unit'}  rules={[{required: true, message: '请填写发证单位'}]}>
                        <Input type="text" autoComplete="off"  placeholder="请填写发证单位"/>
                    </Form.Item>
                    <Form.Item label={'发证时间'}  name="issuing_time" rules={[{required:true,message:'请选择发证时间'}]}>
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    <Form.Item label={'证书扫描件'}  name="certificate" rules={[{required:true,message:'请上传证书扫描件'}]}>
                        <UploadImage uploadNum={5} />
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="回避信息"
                    visible={ modalFlag === 'avoid' }
                    centered
                    destroyOnClose
                    forceRender
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item label='单位名称' name={'store_name'} rules={[{required: true, message: '请填写回避单位全称'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写回避单位全称"/>
                    </Form.Item>
                    <Form.Item label='回避原因' name={'remark'}>
                        <TextArea placeholder="请填写回避原因"/>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="其他材料"
                    visible={ modalFlag === 'other' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={submitModal}
                    initialValues={modalData}
                >
                    <Form.Item label='名称' name={'name'} rules={[{required: true, message: '请填写材料名称'}]}>
                        <Input type="text" autoComplete="off" placeholder="请填写材料名称"/>
                    </Form.Item>
                    <Form.Item label='说明' name={'remark'}>
                        <TextArea placeholder="请填写材料说明"/>
                    </Form.Item>
                    <Form.Item label={'其他材料扫描件'}  name={'certificate'}>
                        <UploadImage uploadNum={10} />
                    </Form.Item>
                    <div className={css.btns}>
                        <Button size="large" type="primary" htmlType="submit">确定</Button>
                    </div>
                </Form>
            </Modal>
        </div>

    )
}