import React, {useEffect, useState} from 'react';
import {useDataApi, useUserInfo} from '../../../../hook';
import {useHistory} from 'react-router-dom'
import {BasicDataReady} from "../components/basic-data-ready";

export const Setting = ()=>{
    const [type, setType] = useState( 3); //3 评标专家 4 行业专家
    const [initData, setInitData] = useState({isLoading: false});
    const {push} = useHistory();
    const [{data: expertInfo, isLoading}, updateParams] = useDataApi({url: '/api/expertInfo', method: 'post', initialData: []});

    useEffect(() => {
        const _data = expertInfo;

        if (!isLoading) {
            _data.isLoading = true;
            setInitData({..._data})
        }

    }, [isLoading]);
    return (
        <div>
            <BasicDataReady type={type} data={initData}/>
        </div>

    )
}