import React, {useState} from 'react';
import css from './reg-box.module.less';
import {Button, Checkbox, Form, Input, message, Modal} from 'antd';

import {useHistory} from "react-router-dom";
import {EXPERT_PACTION, PRIVACY_PACTION, SERVICE_PACTION} from "../../../helps/helpsPaction";
import {ExpertModal, ServicesModel} from "../../../common";
import {store} from "../../../helps";

const layout = {
    labelCol: {span: 9,},
    wrapperCol: {span: 8},
};

export const RegBox  = (props)=>{
    const type = props.type;

    const [form] = Form.useForm();
    const {push} = useHistory();
    const [count, setCount] = useState(60);
    const [pactionFlag, setPactionFlag] = useState(false);
    const [countdown, showCountdown] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [serviceVisible, setServiceVisible] = useState(false);

    const pactionChange = (e) => {
        setPactionFlag(e.target.checked);
    };
    const onRegSubmit = () => {
        const _data = form.getFieldsValue();
        if (!pactionFlag) {
            return message.error({
                style: {
                    marginTop: '20vh',
                }, content: '请先阅读《网站服务协议》和《隐私保护声明》'
            });
        }

        ExpertModal.register({type, ..._data}).then((res) => {
            const {data, code, msg} = res;
            if (code === 200) {
                store.remove('userInfo');
                push('/expert/reg/1/' + type);
            } else {
                message.error(msg);
            }
        })
    };
    const validatorMobile = (mobile) => {
        return new Promise((resolve, reject) => {
            ServicesModel.verifyMobile({type, mobile}).pipe().subscribe({
                next({data}) {
                    if (data) {
                        reject('手机号码已存在');
                    } else {
                        resolve();
                    }
                }
            })
        })
    }
    /** 获取手机验证码 */
    const getCode = () => {
        const mobile = form.getFieldValue('mobile');
        if (!mobile) {
            return message.error("请先输入手机号码");
        } else if (mobile.length !== 11) {
            return message.error("手机号码不正确");
        }
        startCountdown();
        ServicesModel.getSendCode({mobile, event: type === '3' ? 'exregister' : 'hrexregister'}).pipe().subscribe({
            success(res) {
                message.success("验证码已发送，请注意查收！")
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);

    };

    return (
        <div className={ css.regBox }>
            <Form
                form={form}
                {...layout}
            >
                <Form.Item label={'用户名'} name={'nickname'}
                           extra={'注：用户名为6-20位数字字母组合'}
                           rules={[{required: true, message: '请输入用户名'}]}>
                    <Input placeholder={'请输入用户名'} size={"large"}/>
                </Form.Item>
                <Form.Item label={'手机号码'} name="mobile" validateFirst={true}
                           rules={[{required: true, message: '请填写手机号码'}, {
                               len: 11,
                               message: '手机号码不正确'
                           }, () => ({
                               validator(rule, value) {
                                   if (value && value.length === 11) {
                                       return validatorMobile(value);
                                   } else {
                                       return Promise.reject('手机号码不正确')
                                   }
                               }
                           })]}>
                    <Input maxLength={11} size={"large"} placeholder="请输入手机号码" autoComplete="off"/>
                </Form.Item>
                <Form.Item
                    label="手机验证码"
                    name={'code'}
                    rules={[{required: true, message: '请输入手机验证码'}]}
                >
                    <Input placeholder='请输入手机验证码' size={"large"} autoComplete="off" suffix={
                        <div>
                            {!countdown ?
                                <div className={css.codeBtn} onClick={() => {
                                    getCode();
                                }}>获取验证码</div>
                                :
                                <div className={css.codeBtn}
                                     style={{color: "#eaeaea"}}>{count}s后获取</div>}
                        </div>
                    }/>
                </Form.Item>
                <Form.Item label={'设置密码'} extra={'注：密码需由6-20位数字字母组合'} name="password"
                           rules={[{required: true, message: '请输入密码'}, {
                               type: 'string',
                               min: 6,
                               max: 20,
                               message: '密码长度不正确'
                           }]}>
                    <Input.Password size={"large"} placeholder="请输入密码"/>
                </Form.Item>

                <Form.Item label={'确认密码'} name="repassword"
                           rules={[{required: true, message: '请确认密码'}, () => ({
                               validator(rule, value) {
                                   if (value !== form.getFieldValue('password')) {
                                       return Promise.reject('两次输入的密码不一致');
                                   }
                                   return Promise.resolve();
                               },
                           })]}>
                    <Input.Password size={"large"} placeholder="请确认密码"/>
                </Form.Item>
                <Form.Item className={css.pactionText} wrapperCol={{offset: 9, span: 16}}>
                    <Checkbox onChange={pactionChange}>
                        我已阅读并同意
                        <Button style={{padding: '0', height: '20px', lineHeight: '20px'}}
                                type={"link"} onClick={() => {
                            setModalContent(SERVICE_PACTION);
                            setServiceVisible(true)
                        }}>《网站服务协议》</Button>、
                        <Button onClick={() => {
                            setModalContent(PRIVACY_PACTION);
                            setServiceVisible(true)
                        }} style={{padding: '0', height: '20px', lineHeight: '20px'}}
                                type={"link"}>《隐私保护声明》</Button>和
                        <Button onClick={() => {
                            setModalContent(EXPERT_PACTION);
                            setServiceVisible(true)
                        }} style={{padding: '0', height: '20px', lineHeight: '20px'}}
                                type={"link"}>《专家注册须知》</Button>
                    </Checkbox>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 9, span: 8}}>
                    <div className={css.btns}>
                        <div className={css.btn} onClick={() => onRegSubmit()}>提交注册</div>
                    </div>
                </Form.Item>
            </Form>
            <Modal  destroyOnClose visible={serviceVisible} closable={false} footer={null} width={'800px'}>
                <div style={{height: '600px', overflow: 'auto', marginTop: '20px'}}>
                    <div dangerouslySetInnerHTML={{__html: modalContent}}/>
                    <div style={{textAlign: 'center'}}>
                        <Button type={"primary"} onClick={() => setServiceVisible(false)}>我知道了</Button>
                    </div>
                </div>

            </Modal>
        </div>

    )
}