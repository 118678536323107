import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import classNames from 'classnames';
import {Breadcrumb, CardBox, DefaultList, YcLoading} from '../../components';
import {message} from 'antd';
import {HeartFilled, HeartOutlined} from '@ant-design/icons';
import css from './view.module.less';
import {useDataApi} from "../../hook/dataApi";
import {PublishModal} from "../../common";
import {tap} from "rxjs/operators";


export const BaikeView = (props) => {

    const {c_id: cId, id:initId} = props.location.state;
    const [id] = useState(initId);
    let [{data,setData}, updateParams] = useDataApi();

    let [{data: hotList, isLoading: hotLoading}] = useDataApi({
        url: '/api/newsList',
        method: 'post',
        data: {c_id2: 250, type_id: 0, flag:'hot',page: 1, pagesize: 8}
    });

    useEffect(() => {
        if (id) {
            updateParams({url: '/api/getNewsDetail', method: 'post', data: {id}});
        };
    }, [id, updateParams]);

    let {push} = useHistory();

    /** 显示更多 */
    const toMorePage = (type) => {
        if (type === 'hot') {
            push({pathname:'/category/148'});
        } else {
            push({pathname:'/category/148'});
        }
    };
    /** 查看资讯详情 */
    const toViewPage = (id) => {
        push('/category/148/177/view/'+id);
    };
    /** 收藏 */
    const handleCollect = ()=>{
        PublishModal.collect({id:data.id,type:4}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        PublishModal.cancelCollect({id:data.id,type:4}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    return (
        <div className="container">
            {data.title &&  <Breadcrumb list={ [{name:'招标百科',path:'/baike?c_id=' + cId},{name:data.title}] } />}
            <div className={css.innerMain}>
                <div className={css.listWrap}>
                    <div className={css.viewWrap}>
                        <div className={css.titleInfo}>
                            <h2 className={css.viewTitle}> {data.title}</h2>
                            <div className={css.titleInfoWarp}>
                                <div><span>时间： {data.createtime}</span></div>
                                <div><span>来源：{data.source}</span></div>
                                <div><span>点击量：{data.read_num}</span></div>
                                {
                                    data.is_collect ?  <div className={classNames(css.collect, css.active)} onClick={handleCancelCollect}><HeartFilled /><span>&nbsp;&nbsp;取消收藏</span></div> :<div className={classNames(css.collect)} onClick={handleCollect}><HeartOutlined /><span>&nbsp;&nbsp;我要收藏</span></div>
                                }
                                {/*<div className={classNames(css.collect, css.active)}>*/}
                                {/*    <span>收藏</span><Icon type='star' theme={data.is_collect ? 'filled' : ''}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={css.desInfo} dangerouslySetInnerHTML={{__html: data.content}}/>
                    </div>
                </div>

                <div className={css.sideBar}>
                    <CardBox title="热点新闻" more={() => {
                        toMorePage('hot')
                    }}>
                        {hotLoading ? <YcLoading/> : <DefaultList items={hotList.data} goDetail={toViewPage}/>}
                    </CardBox>
                    <div style={{marginBottom: 20}}></div>
                    {/*<CardBox title="项目推荐" more={() => {*/}
                    {/*    toMorePage('commend')*/}
                    {/*}}>*/}
                    {/*    {commendLoading ? <YcLoading/> :*/}
                    {/*        <DefaultList items={commendList.data} goDetail={toViewPage}/>}*/}
                    {/*</CardBox>*/}
                </div>
            </div>
        </div>
    )
}