import React, {Fragment, useEffect, useState} from 'react';
import {Card} from '../components';
import {YcPagination} from '../../../../components';
import classNames from 'classnames';
import {Button, Form, Input, Tooltip} from 'antd';
import {createFromIconfontCN} from '@ant-design/icons';
import {useDataApi} from '../../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {MODE_OFF} from "../../../../common/static_number";
import IconEmpty from 'aliasimgurl/icon_empty.png';

const {Search} =Input;
const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_tljpanwd0oh.js',
});

/** 项目评分 */
export const ProComment = (props) => {

    const [params, setParams] = useState({page:1,type:'6',total:0, project_name: props.location.state ? props.location.state.project_sn : ''});
    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({initialData:{data:[]}});

    useEffect(() => {
        if (!isLoading) {
            const _params = params;
            _params.total = dataSource.count;
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {project_name,type,page} =params;
        updateParams({
            url: '/api/user/openTender',
            method: 'post',
            data: {bid_type:2,page, status:type,search_name:project_name}
        })
    }, [params]);

    const {push} = useHistory();

    const handleSearch =(value)=>{
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});

    };

    const menu =(opt)=>{
        const {status,is_submit} = opt;
           return  (<Fragment>
               { status === 38 ||status === 14 &&  <Button className={classNames(css.btn,css.confirmBtn)} onClick={(e) => {e.stopPropagation();push({pathname: '/publish/commune', state: {project_sn: opt.project_sn}})}}>答疑列表</Button>}
                  {is_submit === 1? <Button className={classNames(css.btn,css.confirmBtn)} onClick={(e)=>{e.stopPropagation();push({pathname:'/expert/marker',state:{project_sn:opt.project_sn}})}}>查看</Button> :
                      status === 38 ||status === 14 ? <Button className={classNames(css.btn,css.rejectBtn)}  onClick={(e)=>{e.stopPropagation();push({pathname:'/expert/marker',state:{project_sn:opt.project_sn}})}}>评标</Button>: ''}
               </Fragment>);
    };
    return (
        <div className={css.body}>
                <Card title="评标项目">
                    <div className={css.list}>
                    {!isLoading &&  dataSource.list.map(opt=>
                        <div className={css.item} key={opt.project_sn} onClick={()=>push({pathname:'/expert/market/view',state:{project_sn:opt.project_sn}})}>
                            <div className={classNames(css.statusBox,{2:css.awaitText,7:css.successText,4:css.rejectText,5:css.rejectText}[opt.bstatus])}>
                                <span className={css.status}><IconFont type={'icon-zhuangtai'} className={css.icon}/>{
                                    (()=>{
                                        switch (opt.bstatus) {
                                            case 2 :
                                                return '开始评标';
                                            case 4 :
                                                return '评标结束';
                                            case 5 :
                                                return '评标完成';
                                            case 7 :
                                                return '评标中';
                                            default: return ''
                                        }
                                    })()

                                }</span>
                            </div>
                            <div className={css.content}>
                                <div>项目名称：<span>{opt.project_name}</span></div>
                                <div>招标单位：<span>{opt.company_name}</span></div>
                                <div>项目金额：<span>{opt.starting_price}(万元)</span></div>
                                <div>项目编号：<span>{opt.project_sn}</span></div>
                                <div>所属行业：<span>{opt.industry_type }-{opt.industry_id}</span></div>
                                <div>招标方式：<span>{opt.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}</span></div>
                                {/*<div>招标类型：<span>{opt.tendering_type}</span></div>*/}
                                <div>评标时间：<span>{opt.evaluationstart_time} 至 {opt.evaluationend_time}</span></div>
                                <div>评标地点：<span>{opt.address}</span></div>
                                <div>抽取人数：<span>{opt.number}人</span></div>
                            </div>
                            <div className={css.operation}>
                                {menu(opt)}
                            </div>
                        </div>
                    )}
                        {
                            !isLoading &&  !dataSource.list.length && <div class={'emptyBox'}>
                                <img src={IconEmpty} alt=""/>
                                <div >暂无数据</div>
                            </div>
                        }
                    <YcPagination
                        total={ params.total || 0 }
                        pageSize={8}
                        current={ params.page }
                        onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                        style={ {margin:'30px 0'} } />
                    </div>
                </Card>
            </div>
    )
}