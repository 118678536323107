import React from 'react';
import css from '../reg.module.less';
import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import icon01 from 'aliasimgurl/reg_index_01.png';
import icon02 from 'aliasimgurl/reg_index_02.png';
import icon03 from 'aliasimgurl/reg_index_03.png';

export const RegBidIndex = (props) => {
    const {push} = useHistory();
    const onApply =()=>{
        push('/expert/reg/0/3');
    };

    return (
        <div className={css.box}>
            <div className={'container'} style={{background: '#fff'}}>
                <div className={classNames(css.regIndex)}>
                    <div className={css.top}>
                        <div className={css.contact}>咨询热线:0871-65819655</div>
                        <div className={css.title}>评标专家申报条件</div>
                        <div className={css.subtitle}>符合评标专家库入选条件，并自愿加入评标专家库的专家：</div>
                        <p>（一）热爱招标事业，积极参加招标的评审活动；</p>
                        <p>（二）熟悉有关招标投标的法律、法规、规章和政策；</p>
                        <p>（三）有良好的政治素质和职业道德，遵纪守法；</p>
                        <p>（四）具有大学本科或同等以上学历；</p>
                        <p>（五）具有高级技术、经济职称或同等专业水平，并从事相关领域八年以上（从事高新技术领域工作的专家以上条件可适当放宽）；</p>
                        <p>（六）熟悉本专业领域国内外技术水平和发展动向；</p>
                        <p>（七）身体健康，能胜任评标工作（年龄原则上不超过70岁）。符合评标专家库入选条件，并自愿加入评标专家库的专家。</p>
                        <div className={css.title}>评标专家申报条件</div>
                    </div>
                    <div  className={css.tabs}>
                        <div className={classNames(css.tab,css.line)}>
                            <img src={icon01} alt=""/>
                            <div className={css.tabTitle}>提交资料</div>
                            <div className={css.tabSubtitle}>需填写个人基本信息、工作履历、执业资格等基础资料</div>
                        </div>
                        <div className={classNames(css.tab,css.line)}>
                            <img src={icon02} alt=""/>
                            <div className={css.tabTitle}>资料审核</div>
                            <div className={css.tabSubtitle}>递交的信息材料由专家智库平台进行审核</div>
                        </div>
                        <div className={css.tab}>
                            <img src={icon03} alt=""/>
                            <div className={css.tabTitle}>正式入库</div>
                            <div className={css.tabSubtitle}>入库成为正式专家后可参与项目评标</div>
                        </div>
                    </div>
                    <div className={css.btns}>
                        <div className={css.btn} onClick={onApply}>立即申请</div>
                    </div>

                </div>

            </div>
        </div>
    );
}