import React, {useState,} from 'react';
import {Button, Form, Input, message, Tooltip} from 'antd';
import {NavLink} from 'react-router-dom';
import {tap} from "rxjs/operators";
import {ServicesModel} from '../../../common';
import {ImgVerifyCode} from '../../../components';
import css from './index.module.less';
import recoverBg from 'aliasimgurl/recover_bg.png';

import {createFromIconfontCN} from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_n7xfb49k5dp.js',
});

export const Recover = (props) => {
    const {type} = props.match.params? props.match.params :{};

    const [form] = Form.useForm();
    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);

    const onSubmit = data => {
        // apply_type:	1企业 2个人 3专家
        ServicesModel.doRetrievepwd({type:'mobile',apply_type:type,...data}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:(res)=>{
                message.success("修改成功",2, ()=>window.location.href = '/expert')
            }
        });
    };
    /** 获取手机验证码 */
    const getCode = () => {
        const mobile = form.getFieldValue('mobile');
        if(!mobile){
            return message.error("请先输入手机号码");
        }else if( mobile.length !==11){
            return message.error("手机号码不正确");
        }

        startCountdown();
        ServicesModel.getSendCode({mobile, event: type === 3?  'exresetpwd':'hrexresetpwd'}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            success(res) {
                message.success("验证码已发送，请注意查收！")
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        setCount(60);
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                }
            }
            , 1000);

    };
    console.log("type",type);
    return (
        <div className={ css.iPage }>
                <div className={ css.iForm }>
                    <div className={css.leftBg}>
                        <img src={recoverBg} alt=""/>
                        {type == 4 ? <span>找回行业专家密码</span>:
                        <span>找回评标专家密码</span>}
                    </div>
                    <div className={ css.recoverWrap }>
                        <Form onFinish={onSubmit} form={form}   className={ css.formWrap }>
                            <Form.Item name={'mobile'} rules={[{required:true,message:'请输入用户名或手机号码'}]}>
                                <Input size={"large"} prefix={ <IconFont type={'icon-ren'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder="请输入手机号码" style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}} autoComplete="off" />
                            </Form.Item>
                            <Form.Item name="captcha" rules={[{required:true,message:'请输入图形验证码'}]}>
                                <Input size={"large"} suffix={<ImgVerifyCode className={ css.imgcode } />} prefix={ <IconFont type={'icon-icon_duihao-mian'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder="请输入图形验证码" style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}} autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                name={'code'}
                                rules={[{required: true ,message:'请输入手机验证码'}]}
                            >
                                <Input size={"large"} prefix={ <IconFont type={'icon-shouji'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder='请输入手机验证码' style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}}  autoComplete="off" suffix= {
                                    !countdown ? <a type="primary" onClick={() => {
                                                getCode();
                                            }} style={{color: '#645ECC',paddingLeft:'20px' ,borderLeft: '1px solid #ccc'}}>获取验证码</a>
                                      : <a type={"primary"} style={{color: "#999"}}>{count}s后获取</a>
                                }/>
                            </Form.Item>
                            <Tooltip title={<span>长度为6~20个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                                <Form.Item  name="newpassword" rules={[{required: true ,message:'请输入设置新密码'},{type:'string',min:6,max:20,message:'密码长度不正确'}]}>
                                    <Input.Password size="large" prefix={ <IconFont type={'icon-suo'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder="请输入设置新密码" style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}}  />
                                </Form.Item>
                            </Tooltip>
                            <div className={ css.btnWrap }>
                                <div className={ css.btnInner }>
                                    <Button size="large" type="primary" htmlType="submit">重置密码</Button>
                                </div>
                            </div>
                            <div className={ css.goLogin } >
                                    <span>已有平台账号？</span>
                                    <NavLink to="/expert">去登录</NavLink>

                            </div>
                        </Form>
                    </div>
                </div>
            </div>

    )
}