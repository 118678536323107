import React, {useEffect, useState} from 'react';
import {Card} from '../components';
import {YcPagination} from '../../../../components';
import classNames from 'classnames';
import {useDataApi} from '../../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {PublishModal} from "../../../../common";
import {EmptyData} from "../../../../components/empty-data";
import iconRead from "aliasimgurl/icon_read.png";
export const Message = () => {

    const pList = [{
        label: '邀请通知',
        key: '5'
    }, {
        label: '评分通知',
        key: '6'
    }, {
        label: '系统通知',
        key: '7'
    }];



    const [dataList, setDataList] = useState([]);
    const [params, setParams] = useState({category: '5'});

    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi();
    useEffect(() => {
        console.log(pList[0]);
        if (!isLoading) {
            const {data = [], total} = dataSource;
            const _params = params;
            _params.total = total;
            setDataList(data);
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {page=1,category} = params;
        updateParams({
            url: '/api/user/NoticeList',
            method: 'post',
            data: {page, category}
        });
    }, [params]);
    const {push} = useHistory();
    const goDetail = ({project_sn,category,type,status,id}) => {
        const callback = ()=>{
            skip({project_sn,category,type});
        }
        markRead({project_sn,category,type,status,id,callback});
    };
    const markRead = ({project_sn,category,type,status,id,callback=()=>{}},index=null)=>{
        if(!status){
            PublishModal.updateNotice({project_notice_id:id}).pipe().subscribe({
                next:res=>{
                    callback();
                    if(index !== null){
                        const _dataList = dataList;
                        _dataList[index].status = 1;
                        setDataList(_dataList);

                    }


                },
                error:err=>{},
                complete:()=>{}
            });
        }else{
            callback();
        }
    };

    const typeChange = (e)=>{
        let  _params = params;
        _params.category = e;
        setParams({..._params})
    }

    const skip = ({category,type,project_sn})=>{
        switch (category) {
            case 5 : //邀请通知
                push({pathname: '/expert/invite',state:{project_sn}});
                break;
            case 6 : //评分信息
                push({pathname:'/expert/comment',state:{project_sn,}});
                break ;
            default: break;
        }
    }

    const allReady = ()=>{
        PublishModal.resetMessage({type: params.category}).pipe().subscribe({
            next:res=>{
                window.location.reload();
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    return (
        <div className={css.mainBody} >
            {!isLoading && <Card title="消息通知" extra={<div className={css.read} onClick={allReady}><img className={css.icon} src={iconRead} alt=""/><span>全部标为已读</span></div>}>

                <div className={css.tabs}>
                    {
                        pList.map((opt) =>
                            <div className={classNames(css.tab,  opt.key === params.category? css.active:'')} onClick={() => typeChange(opt.key)} key={opt.key}>{opt.label}
                            </div>
                        )
                    }
                </div>



                <div className={css.info}>
                    <div className={css.row}>
                        <div className={css.title}>通知</div>
                        <div className={css.time}>时间</div>
                        <div className={css.status}>状态</div>
                        <div className={css.action}>操作</div>
                    </div>
                    {
                        dataList.map((opt,index) =>
                            <div key={opt.id} className={css.row}>
                                <div className={classNames(css.title,"line2")}>{opt.content}</div>
                                <div className={css.time}>{opt.createtime}</div>
                                <div className={css.status} style={{color: !opt.status? '#E5574C':''}}>{opt.status_text}</div>
                                <div className={css.action}>
                                    <div className={css.btn} onClick={()=>goDetail(opt)}>查看</div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !isLoading &&  !dataList.length && <EmptyData />
                    }
                    <YcPagination style={ {margin:'30px 0'} }  total={ dataSource.total || 0 }
                                  current={ params.page }
                                  onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}/>
                </div>
            </Card> }
        </div>
    )
}