import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './notices-scroll.module.less';

export const NoticesScroll =(props)=>{

    const {items=[],goDetail} = props;
    const [swiper, updateSwiper] = useState(null);
    const params = {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        // centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        getSwiper: updateSwiper
    }

    const handleMouseOver = ()=>{
        swiper.autoplay.stop()
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start()
    }
    return (
        <div className={ css.list } >
            {items.length &&  <Swiper { ...params } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                {
                    items.map((opt,index)=>
                        <div className={ css.item } key={ index } onClick={()=>goDetail(opt)}>
                            <div className={ css.title }>{ index+1 }. { opt.title }</div>
                        </div>
                    )
                }
            </Swiper>}

        </div>
    );
}