import React, {useState,Fragment} from 'react';
import css from './applyDetail.module.less';
import {Button, Descriptions,message, Modal,Form,Input} from "antd";
import {UploadImage} from "../../components";
import {useHistory} from 'react-router-dom';
import {useDataApi} from "../../hook";
import {resourcesUrl} from "../../common/static_number";
import {PublishModal} from "../../common";

const {TextArea} = Input;
export const ApplyDetail = (props) => {
    const { project_sign_id='',readonly=''} = props.location.state;
    const [refuseModal, showRefuseModal] = useState(false);
    const [rejectModal, showRejectModal] = useState(false);
    const [{data,setData, isLoading}] = useDataApi({url: '/api/signDetail', method: 'post', data: {project_sign_id}});
    const history = useHistory();
    const [content, setContent] = useState('');//审批意见


    const handleRefuse = () => {
        if (!content) {
            message.error('请输入审批意见');
            return;
        }
        handleAudit({status: '12'});
    };

    const handleReject = () => {
        if (!content) {
            message.error('请输入驳回原因');
            return;
        }
        handleAudit({status: '25'});
    };

    /** 提交审批结果 */
    const handleAudit = ({status = '11'}) => {
        const {id:prosign_id} = data;
        PublishModal.saveQualificationAudit({
            prosign_id,
            status,
            reject_remark:content
        }).then(({data: { code, msg}}) => {
            if (code === 200) {
                message.success('提交成功',5);
                const _initialData = data;
                _initialData.reject_remark = content;
                _initialData.sign_status = status;
                setData({..._initialData});
                showRefuseModal(false);
                showRejectModal(false);
            } else {
                message.error(msg);
            }
        })
    };

    const handleRepeal = ()=>{
        const _initialData = data;
        _initialData.sign_status = '2';
        setData({..._initialData});
    };

    return (
        <div className="container" >
            <div className={css.apply}>
                <div className={css.boxTitle}>
                    报名信息
                    {!readonly &&   <div className={css.info}>
                        <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>修改</Button>
                    </div>}
                </div>
                {!isLoading &&<Descriptions column={1} bordered>
                    <Descriptions.Item label="投标联系人">{data.store_user_name}</Descriptions.Item>
                    <Descriptions.Item label="联系人电话"> {data.store_user_phone}</Descriptions.Item>
                    <Descriptions.Item label="企业邮箱"> {data.email}</Descriptions.Item>
                    <Descriptions.Item label="企业资质">
                        <div dangerouslySetInnerHTML={{__html:data.store_remark || ''}} />
                    </Descriptions.Item>
                    {data.qualifications &&
                    <Descriptions.Item label="附件">
                        { data.qualifications .map((item)=>
                            <a href={ resourcesUrl + item.file} style={{display:'block'}} target={'_blank'}>{item.name}</a>
                        )}
                    </Descriptions.Item>}
                    { (data.sign_status === '12' || data.sign_status === '2' || data.sign_status === '25') && data.reject_remark &&
                    <Descriptions.Item label="审批意见">
                        {data.reject_remark}
                    </Descriptions.Item>}
                </Descriptions>
                }
                <div className={css.btns}>
                    { data.sign_status === '2' ? <Fragment>

                        <Button size={"large"} onClick={() => showRefuseModal(true)}>
                            审批不通过
                        </Button>
                        <Button type="primary" size={"large"} className={css.saveBtn}   onClick={()=> showRejectModal(true)}>
                            驳回
                        </Button>
                        <Button type="primary" size={"large"}  onClick={handleAudit}>
                            审批通过
                        </Button>
                    </Fragment> :
                    <Button type="primary" size={"large"} style={{marginLeft: '20px'}} onClick={()=>{history.goBack()}}>
                        返回
                    </Button>}
                </div>
                <Modal visible={refuseModal} title={'审批不通过'} onOk={handleRefuse}
                       onCancel={() => showRefuseModal(false)}>
                    <Form.Item label={'审批意见'} wrapperCol={20}>
                        <TextArea onChange={(e) => setContent(e.target.value)}
                                  style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                    </Form.Item>
                </Modal>
                <Modal visible={rejectModal} title={'驳回'} onOk={handleReject}
                       onCancel={() => showRejectModal(false)}>
                    <Form.Item label={'驳回原因'} wrapperCol={20}>
                        <TextArea onChange={(e) => setContent(e.target.value)}
                                  style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                    </Form.Item>
                </Modal>
            </div>
        </div>
    );
}