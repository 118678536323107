import React, {useEffect, useState} from 'react';
import {Avatar, message, Upload} from 'antd';
import {useUserInfo} from '../../../hook';
import css from './UserInfo.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import moment from "moment";
import classNames from 'classnames';
import {ServicesModel, UploadConfig} from "../../../common";
import {createFromIconfontCN} from '@ant-design/icons';
import {useHistory} from "react-router-dom";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_clv9h5c7isn.js',
});


export const UserInfo  = ()=>{
    const [data,setData] = useUserInfo();
    const {push} = useHistory();
    const action = 'https://upload-z0.qiniup.com';
    const [params,setParams] = useState({});
    const beforeUpload = (file)=> {
        return new Promise((resolve, reject)=>{
            if(file.size > 1024*1024*10){
                message.error('上传图片为' + (file.size/1024/1024).toFixed(2)+'M ，请控制在10M以内！');
                reject();
            } else if( !accept.includes(file.name.slice(file.name.lastIndexOf('.')))  ){
                message.error('图片格式不正确');
                reject();
            }
            UploadConfig.fetchUploadToken().then(({data:{data:{uptoken:token}}})=>{
                // this.setState({
                //     uptoken: token,
                //     filename: file.name
                // });
                setParams({uptoken: token,filename: file.name})
                resolve();
            });

        })
    };
    useEffect(()=>{
        // updateInfo;
        console.log("data=======",data)
    },[data]);
    const accept='.jpg, .jpeg, .png, .gif';
    const handleChange = ({ file,fileList }) =>{
        if(file.status === 'done' || file.status === "removed"){
            ServicesModel.updateAvatar({type:3,avatar:file.response.key}).subscribe({
                next:res=>{
                    // let _t = setTimeout(()=>getData(),5000);
                    // setTimer(_t);
                    ServicesModel.getUserInfo().subscribe({
                        next:({data,code})=>{
                            if (data && data.userinfo) {
                                setData(data.userinfo);
                            }
                        }
                    });
                }
            });
        }
        if(file.status === 'error'){
            message.error("图片上传失败,请重新上传！");
        }
        // this.setState({ fileList })
    };

    return (
        <div className={ css.userinfo }>
            <div className={css.avatarBg}>
                <Upload action={action} accept={accept} beforeUpload={beforeUpload}
                        onChange={handleChange}
                        showUploadList={false}
                        data={{
                            token: params.uptoken,
                            key: moment(new Date()).format('YYYY-MM-DD')+'/'+parseInt(9999*Math.random())+'/' +params.filename
                        }}
                >
                    <div className={css.avatar}>
                        <Avatar src={data.avatar|| avatar}  size={ 74 } />
                        <div className={css.updateAvatar}>
                            修改头像
                        </div>
                    </div>
                </Upload>
            </div>
            <div className={css.info}>
                <div >
                    <span>{data.mobile}</span>
                    {/*{data.is_member === 1 ?<img src={VipMember} alt=""/>: <img src={VipDefault} alt=""/>}*/}
                </div>
                {data.type === 3 && <div className={classNames(css.auth,data.status && data.status == 2? '': css.unauth)}><IconFont type={'icon-renzheng1'} className={'icon'}/>评标专家</div>}
                {data.type === 4 && <div className={classNames(css.auth,data.status && data.status == 2? '': css.unauth)}><IconFont type={'icon-renzheng1'} className={'icon'}/>行业专家</div>}
                <div className={css.other}><span onClick={()=>push('/expert/setting/mobile')}>修改手机号</span> <span onClick={()=>push('/expert/setting/password')}>修改密码</span> </div>
                {/*<div>{data.store_name || data.mobile}</div>*/}
            </div>
            {/*<NavLink className={ css.btn } to="/account/setting/userauth/auth" >马上认证</NavLink>*/}
        </div>

    )
}