import React, {useEffect, useState} from 'react';
import css from './reg.module.less';
import classNames from 'classnames';
import {Form, Tabs,Modal} from 'antd';
import awaitImg from 'aliasimgurl/icon_await.png'
import successImg from 'aliasimgurl/icon_success.png'
import rejectImg from 'aliasimgurl/icon_reject.png'
import {useHistory} from "react-router-dom";
import {LoginSteps} from "../../../components/steps";
import {useDataApi} from "../../../hook";
import {BasicData} from "../components/basic-data";
import {RegBox} from "../components";
import {ServicesModel} from "../../../common";

const {TabPane} = Tabs;

const store = require('store');

export const Reg = (props) => {
    const param = props.match.params ? props.match.params : {};
    const [step, setStep] = useState(param.step ? param.step : '0');
    const [type, setType] = useState(param.type); //3 评标专家 4 行业专家
    const [initData, setInitData] = useState({isLoading: false});
    const {push} = useHistory();
    const [{data: expertInfo, isLoading}, updateParams] = useDataApi({initialData: []});

    useEffect(() => {
        const {type = '', step} = param;
        setType(type);
        setStep(step);
        if (store.get("token")) {
            updateParams({url: '/api/expertInfo', method: 'post', initialData: []});
        }
    }, [param])

    useEffect(() => {
        const _data = expertInfo;

        if (!isLoading) {
            //0=未认证，1=申请中，2=审核通过,3=审核未通过
            if (_data.status === 0) {
                setStep(1);
            } else if (_data.status === 1 || _data.status === 3) {
                setStep(3);
            } else if (_data.status === 2) {
                setStep(1);
            } else {
                setStep(0);
            }
            _data.isLoading = true;
            setInitData({..._data})
        }

    }, [isLoading]);

    const recall = ()=>{
        Modal.confirm({
            title:'系统提示',
            content:'您确定要撤回认证信息的申请嘛？',
            okText:'确定',
            cancelText:'取消',
            maskClosable: true,
            onOk: ()=>{
                ServicesModel.repealApply().pipe(
                ).subscribe({
                    next:res=>{
                        push('/expert/reg/1/'+type);
                    },
                    error:err=>{},
                    complete:()=>{}
                });
            },
            onCancel:()=>{

            }
        });

    }

    return (
        <div className={classNames(css.reg)}>
            <div className={'container'} >
                <LoginSteps current={step}/>
                <div className={css.content}>
                     <Tabs activeKey={step.toString()}>
                       <TabPane tab="填写基本信息" key="0">
                           <RegBox type={type}/>
                        </TabPane>
                        <TabPane tab="完善资料" key="1">
                            {initData.isLoading &&   <BasicData type={type} data={initData}/>}
                        </TabPane>
                        <TabPane tab="等待审批" key="3" className={css.result}>
                            {
                                initData.isLoading &&  initData.status === 1 && <div>
                                    <img src={awaitImg} alt="" className={css.status_img}/>
                                    <div className={css.title}>您的资料提交成功，进入平台审核阶段，请耐心等待！
                                    </div>
                                    <div className={css.subtitle}>如有其它任何疑问，请致电0871-65819655</div>
                                    <div className={css.btns}>
                                        {/*<div className={classNames(css.btn, css.backBtn)} onClick={() => push('/expert/setting')}>返回个人中心</div>*/}
                                        <div className={classNames(css.btn, css.recallBtn)} onClick={recall}>撤回申请</div>
                                    </div>
                                </div>
                            }
                            {
                                initData.isLoading && initData.status === 3 && <div className={css.reject}>
                                    <img src={rejectImg} alt="" className={css.status_img}/>
                                    <div className={css.title}>抱歉,您提交的资料信息审核不通过，请重新填写提交！</div>
                                    <div className={css.remark}>
                                        <span className={css.label}>驳回原因:</span>
                                        <span>{expertInfo.close_reason}</span>
                                    </div>
                                    <div className={css.subtitle}>如有其它任何疑问，请致电0871-65819655</div>

                                    <Form.Item className={css.btns}>
                                        <div className={classNames(css.btn, css.resetBtn)} onClick={() => push({
                                            pathname: '/expert/reg/1',
                                            state: {id: param.id, type: 'edit'}
                                        })}>重新填写
                                        </div>
                                    </Form.Item>
                                </div>
                            }
                            {initData.isLoading && initData.status === 2 &&
                                <div>
                                    <img src={successImg} alt="" className={css.status_img}/>
                                    <div className={css.title}>您提交的资料信息已经通过平台审核，恭喜您成为专家库一员！</div>
                                    <div className={css.subtitle}>如有其他任何疑问，请致电0871-65819655！</div>
                                    <div className={css.btns}>
                                        <div className={classNames(css.btn, css.backBtn)}
                                             onClick={() => push({pathname: '/expert/setting'})}>返回个人中心
                                        </div>
                                    </div>
                                </div>
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}