import React, {Fragment, useEffect, useState} from "react";
import css from './offer.module.less';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {Button, DatePicker, Descriptions, Form, Input, message, Modal} from "antd";
import {useDataApi} from "../../../../hook";
import {AUDIT_CONFIRM_PUBLISH, AWAIT, FAIL, resourcesUrl, SUCCESS} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {TagAudit, UploadFile} from "../../../../components";
import {Contacts} from "../contacts";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {tap} from "rxjs/operators";
import {PreviewMarket} from "../../../preview/previewMarket";

const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 10},
};

export const Offer =(props) =>{
    const {params:{project_sn="ZB2020040610150571"}} = props;
    const [ form ] = Form.useForm();
    const [editor,setEditor] = useState();
    const [params, setParams] = useState({ content: '', file: []});
    const [project, setProject] = useState();
    const [validContent, setValidContent] = useState(true);
    const [users, setUsers] = useState([]); //选中的审批人
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [initialData, setInitialData] = useState({});
    const [previewFlag, showPreview] = useState(false); //预览弹窗控制

    const [{data, isLoading}] = useDataApi({url: '/api/winbidDetail', method: 'post', data: {project_sn}});
    let { push } = useHistory();
    const excludeControls = ['media', 'emoji'];
    useEffect(() => {
        if (!isLoading) {
            const _data = data;
            const _param = params;
            _param.file = _data.file || [];
            setParams(_param);
            setEditor(BraftEditor.createEditorState(_data.content));
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);
    /** 富文本内容改变事件 */
    const handleEditorChange = (value) => {
        setEditor(value);
        setValidContent(true);
    };
    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        let _params = params;
        _params.file = val;
        setParams(_params);
    };
    /** 提交验证 */
    const submitVerify = () => {
        form.validateFields().then((values) => {
            if (editor.toHTML() === '<p></p>') {
                setValidContent(false);
                return
            }
            showContactsVisible(true);
        })
    };
    const submit = ({is_approval = 0}) => {

        const values =  form.getFieldsValue(['title']);
        let _params = Object.assign({...values}, {...params});
        _params.content = encodeURI(editor.toHTML());
            PublishModal.saveNoticeConfirm({is_approval,project_sn, ..._params}).then(({data: {msg, code}}) => {
                if (code === 200) {
                    if(is_approval === 0){
                        message.success('保存成功！',3);
                    }else{
                       window.location.reload();
                    };
                } else {
                    message.error(msg)
                }
            })

    };
    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        const audit = users[0];
        PublishModal.setResponsible({
            type: AUDIT_CONFIRM_PUBLISH, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                submit({is_approval: 1});
            } else {
                message.error(msg)
            }
        })
    };
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };

    /** 发布中标公示 */
    const submitPublish = ()=>{
        PublishModal.winBid({project_sn}).pipe(
            tap(res=>console.log(res,'pipe'))
        ).subscribe({
            next:res=>{
                Modal.success({
                    title: '请耐心等待，系统正在审核',
                    centered: true,
                    content: '工作人员将在2个工作日内完成审核！',
                    onOk:()=> {
                        push('/account/project')
                    }
                });
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 查看审批意见 */
    const showModal = ()=>{
        Modal.confirm({
            title:'审批意见',
            content: data.approvalcontent || data.admin_remark,
            okText:'确定',
            cancelText:'取消',
            maskClosable: true,
            onOk: ()=>{

            },
            onCancel:()=>{

            }
        });
    };
    /** 撤回审批  */
    const handleRepeal = ()=>{
        if(data.status === 21){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回中标公示的审批嘛？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }
    };
    const repeal = ()=>{
        PublishModal.repealAudit({operation_type:'winning_bid',project_sn}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                window.location.reload();
            },
            error:err=>{},
            complete:()=>{}
        });
    }

    /** 打开预览 */
    const openPreview = ()=>{
        form.validateFields().then((values) => {
            if (editor.toHTML() === '<p></p>') {
                setValidContent(false);
                message.error('请填写公示正文');
                return
            }
            const completetime =  form.getFieldsValue(['completetime']);
            let _params = Object.assign({...values}, {...params});
            _params.content = editor.toHTML();
            const {address,industry_id:category_name,industry_type:category_trade,user_name,user_phone,images,bond,title,file,project_name,province_id,city_id,county_id,signend_time,signup_time,tendering_type,starting_price} = data;
            setProject ({
                completetime,signend_time,signup_time,bond,title,project_name,
                province_id,city_id,county_id,images,file,user_name,user_phone,
                category_name,category_trade,tendering_type,starting_price,address,
                project_sn, ..._params
            });

            showPreview(true);
        })

    }

    /** 关闭预览弹窗 */
    const closePreview = (value) => {
        showPreview(false);
    };

    return (
        <div className={css.offer}>
            <div className={ css.boxTitle }>
                中标公示{initialData.isLoading}<TagAudit data={{'25':{title:'',index:AWAIT},'26':{title:'',index:AWAIT},
                '21':{title:'审批中',index:AWAIT},'28':{title:'平台审核中',index: AWAIT},
                '32':{title:'平台审核通过',index: SUCCESS}, '22':{title:'审批通过',index: SUCCESS},
                '23':{title:'审批未通过',index: FAIL}, '33':{title:'平台审核未通过',index: FAIL}}[data.status]}/>
               <div className={css.info}>
                        <span >项目名称：</span>
                        <span className={css.high}>{data.project_name}</span>
                        <span >项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                   {
                       data.status !== 26 && ( data.status === 21 || data.status === 33 || data.status === 23 ) &&  <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 21 ? '撤回' : '编辑'}</Button>
                   }</div>

            </div>

            {
                !initialData.isLoading || <Form
                    {...layout}
                    initialValues={initialData}
                    scrollToFirstError = { true}
                    form={form}
                >
                    {data.status === 33 &&
                    <Form.Item label={'平台审核意见'} wrapperCol={{span: 24}}>
                        <span style={{border: '1px solid #999'}}>{initialData.admin_remark}</span>
                    </Form.Item>
                    }
                    {data.status === 23 &&
                    <Form.Item label={'审批意见'}  wrapperCol={{span: 24}}>
                        <span style={{border: '1px solid #999'}}>{initialData.approvalcontent}</span>
                    </Form.Item>
                    }
                    <Form.Item label="中标单位" >
                        <span>{initialData.store_name}</span>
                    </Form.Item>
                    <Form.Item label="公示名称" name={'title'}
                        rules={[{required: true, message: '请填写公示名称'}, {min: 6, message: '项目最少为6个字符'}, {
                        max: 50,
                        message: '公示名称最长为50个字符'
                    }]}>
                        <Input placeholder='请填写公示名称' disabled={ data.status !== 26}/>
                    </Form.Item>

                    <Form.Item
                        label={'公示正文'}
                        wrapperCol={{span: 24}}
                        required
                        validateStatus={validContent ? '' : 'error'}
                        help={validContent ? '' : '请填写公示正文'}
                        style={{marginBottom: 0}}
                    >
                        <BraftEditor
                            excludeControls={excludeControls}
                            value={editor}
                            onChange={handleEditorChange}
                            style={{border: '1px solid #ddd'}}
                            contentStyle={{height: '200px'}}
                            readOnly={ data.status !== 26}
                        />
                    </Form.Item>
                    {params.file.length>0 && (
                             data.status !== 26  ? <Form.Item>
                                {  params.file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                )}
                            </Form.Item> : <Form.Item wrapperCol={{offset: 2, span: 16}}>
                               <UploadFile accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'} uploadNum={3}
                                            handleFileChange={handleFileChange} defaultFileList={params.file}/>
                            </Form.Item>
                        )
                    }

                    <div className={css.btns}>
                        <Button size={"large"} onClick={openPreview}>预览</Button>
                        {
                            initialData.status === 26 && <Fragment>
                                <Button size={ "large"} className={css.yellowBtn} onClick={submit}>
                                    保存
                                </Button>
                                <Button type="primary" size={"large"} onClick={ submitVerify } >
                                    提交审批
                                </Button>
                            </Fragment>

                        }
                        {(data.status === 23||data.status === 33 ) && <Button type="primary" size={"large"} onClick={showModal}>
                            查看审批意见
                        </Button>}

                        {
                            initialData.status === 22 && <Button style={{marginLeft: '20px'}} type="primary" size={"large"} onClick={submitPublish}>
                                发布
                            </Button>
                        }
                    </div>
                </Form>
            }
            <Modal
                title="选择审批人"
                visible={contactsVisible}
                onOk={handleContactsOk}
                onCancel={handleCancel}
            >
                <Contacts success={contactsSuccess} users={users}/>
            </Modal>
            <Modal visible={previewFlag}
                   width={940}
                   onCancel={closePreview}
                   footer={false}
            >
                <PreviewMarket project = {project} />

            </Modal>
        </div>
    )
}
