import {aesDecrypt, aesEncrypt, store} from '../helps';
import React, {useEffect, useState} from 'react';
import {useIsLogin} from './isLogin';
import {ServicesModel} from '../common';
import {Modal} from "antd";
import {useHistory} from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const useUserInfo = ()=>{

    const [isLogin] = useIsLogin();
    const [data,setData] = useState({});
    const [loading,setLoadig] = useState(true);
    const [update,updateInfo] = useState(false);
    const {push } =useHistory();

    useEffect(()=>{
        if (isLogin) {
            let userInfo = store.get('userInfo');
            if (userInfo) {
                setData(aesDecrypt(userInfo));
                setLoadig(false);
            }else{
                updateUserInfo()
            };
        }else{
            setData({});
            setLoadig(false);
        }
    },[isLogin]);
    useEffect(()=>{
        console.log("方法进入");
        if(update){

            updateUserInfo();
        }
    },[update]);
    setTimeout(()=>{

    },1000)
    const updateUserInfo = ()=>{
        console.log("====================");
        ServicesModel.getUserInfo().subscribe({
            next:({data})=>{
                if (data && data.userinfo) {
                    if(data.userinfo.apply_state == 0 &&data.userinfo.type != 3){
                        Modal.confirm({
                            icon: <ExclamationCircleOutlined/>,
                            title:'温馨提示',
                            content: '您的账号尚未完成认证，可能会影响部分功能体验。',
                            okText:'立即认证',
                            cancelText:'取消',
                            maskClosable: true,
                            onOk: ()=>{
                                push('/account/setting/userauth');
                            },
                            onCancel:()=>{

                            }
                        });
                    }
                    console.log("====================");
                    store.set('userinfo',aesEncrypt(data.userinfo),new Date().getTime() + 1000*60*60*24);
                    setData(data.userinfo);
                    setLoadig(false);
                }
            }
        });
    }

    return [data,setData,updateInfo,loading];
}