import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {Bidding, Marke} from "./components";
import css from "./index.module.less";
import {Flow} from "../../../components";

const { TabPane } = Tabs;

export const Marker = (props) => {
    const superStep = 2; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(1);
    const [params,setParams] = useState({project_sn: props.location.state.project_sn ||''});

    useEffect(()=>{
        const {project_sn='',step=1} = props.location.state||{};
        console.log("project_sn=============",project_sn);
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);

    let stepList = [
        {
            stepId: 1,
            stepName: '查看招标文件',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '评分',
            disabled: true

        },
    ];
    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList}  />
            <div className={css.marker}>
                <Tabs activeKey={ step.toString() }>
                    <TabPane tab="查看招标公告" key="1">
                        <Bidding  params={params}/>
                    </TabPane>
                    <TabPane tab="评分" key="2" >
                        <Marke params={params}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>



    );
}