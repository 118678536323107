import React from 'react';
import css from '../reg.module.less';
import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import icon01 from 'aliasimgurl/reg_index_01.png';
import icon02 from 'aliasimgurl/reg_index_02.png';
import icon03 from 'aliasimgurl/reg_index_03.png';

export const RegIndustryIndex = (props) => {
    const {push} = useHistory();
    const onApply =()=>{
        push('/expert/reg/0/4');
    };

    return (
        <div>
            <div className={css.box}>
                <div className={'container'} style={{background: '#fff'}}>
                    <div className={classNames(css.regIndex)}>
                        <div className={css.top}>
                            <div className={css.contact}>咨询热线:0871-65819655</div>

                            <div className={css.title}>行业专家申报条件</div>
                            <div className={css.subtitle}>符合行业专家智库入选条件，并自愿加入行业专家库的专家：</div>

                            <p>（一）具有对国家和行业负责的态度，具有良好的职业道德，坚持原则，办事公正，无不良业绩；</p>
                            <p>（二）对所在行业各专业技术、管理、财务、安全等工作有较丰富的具体工作经验 ；</p>
                            <p>（三）对专业领域有丰富的专业知识和实践经验，对行业的发展现状和发展趋势有较深入的了解，了解国家相关政策、法规；</p>
                            <p>（四）具有大学本科或同等以上学历，申报专业与学历专业相关；</p>
                            <p>（五）具有高级技术、经济职称或同等专业水平，并从事相关领域八年以上（从事高新技术领域工作的专家以上条件可适当放宽）；</p>
                            <p>（六）熟悉本专业领域国内外技术水平和发展动向；</p>
                            <p>（七）身体健康，能胜任相关行业工作。</p>
                            <div className={css.title}>行业专家申请流程</div>
                        </div>

                        <div  className={css.tabs}>
                            <div className={classNames(css.tab,css.line)}>
                                <img src={icon01} alt=""/>
                                <div className={css.tabTitle}>提交资料</div>
                                <div className={css.tabSubtitle}>需填写个人基本信息、工作履历、 执业资格等基础资料</div>
                            </div>
                            <div className={classNames(css.tab,css.line)}>
                                <img src={icon02} alt=""/>
                                <div className={css.tabTitle}>资料审核</div>
                                <div className={css.tabSubtitle}>递交的信息材料由专家智库平台进行审核</div>
                            </div>
                            <div className={css.tab}>
                                <img src={icon03} alt=""/>
                                <div className={css.tabTitle}>正式入库</div>
                                <div className={css.tabSubtitle}>入库成为正式专家后可参与行业相关工作</div>
                            </div>
                        </div>
                        <div className={css.btns}>
                            <div className={css.btn} onClick={onApply}>立即申请</div>
                        </div>
                        {/*<div className={css.footer}>加入专家库咨询热线:0871-65819655</div>*/}
                    </div>

                </div>
            </div>
        </div>
    );
}