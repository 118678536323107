import {store} from '../helps';
import {useEffect, useState} from 'react';

export const useIsLogin = ()=>{

    const [isLogin,setIsLogin] = useState(false);
    useEffect(()=>{
        let _isLogin = store.get('token');
        setIsLogin(!!_isLogin);
    },[isLogin]);

    return [isLogin,setIsLogin];
}