import React, {Fragment, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {UploadImage} from '../../components';
import {Descriptions, Modal} from 'antd';
import {useDataApi, useIsLogin, useUserInfo} from '../../hook';
import css from './index.module.less';
import {resourcesUrl} from "../../common/static_number";


export const PreviewBidding = (props) => {
    const {project:data={}} = props;
    const [isLogin] = useIsLogin();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage] = useState(''); //默认的图片列表
    const [{data:address},updateParams] = useDataApi();
    const [userInfo] = useUserInfo();

    useEffect(() =>{
        console.log("data ======",data);
        const {province_id,city_id,county_id} = data;

        if(city_id){
            updateParams({url: '/api/getRegion', method: 'post', data: {province_id,city_id,county_id}});
        }
    },[data]);

    const handleCancel = () => setPreviewVisible(false);

    return (
        <div className="">
            {
                <Fragment>
                <div className={ css.innerMain }>
                    <div className={ css.listWrap }>
                        <div className={ css.viewWrap }>
                            <div className={ css.titleInfo }>
                                <h2 className={ css.viewTitle }>{data.project_name}</h2>
                                <p>
                                    {/*<i className="iconfont iconweizhi" /><span>{address.pname}-{address.cname}-{address.uname}</span>*/}
                                    <i className="iconfont iconrili" /><span>未发布</span>
                                    <i className="iconfont iconshoucangon"/><span style={{cursor: "pointer"}}>收藏</span>
                                </p>
                            </div>
                            <div className={ css.extInfo }>
                                <table>
                                    <tbody>
                                        <th>项目编号</th>
                                        <td>{data.project_sn}</td>
                                        <th>项目名称</th>
                                        <td >{data.project_name}</td>
                                        <th>采购物品</th>
                                        <td>{data.product_name}</td>
                                        <th> 所属行业</th>
                                        <td>{data.category_trade}-{data.category_name}</td>

                                        <th>采购人/单位</th>
                                        <td>{userInfo.store_name}</td>
                                        <th>所属地区</th>
                                        {/*<td>{address.addess}</td>*/}

                                        <th>采购总量</th>
                                        <td>{data.total}{data.unit_name}</td>
                                        <th>采购单价</th>
                                        <td>
                                            <span>{data.starting_price}(元)</span>
                                        </td>
                                        <th>采购开始时间</th>
                                        <td>
                                            {data.signup_time}
                                        </td>
                                        <th>采购结束时间</th>
                                       <td>{data.signend_time}</td>
                                        <th>联系人</th>
                                        <td>
                                            {data.user_name}
                                        </td>
                                        <th>联系电话</th>
                                        <td>
                                            {
                                                isLogin?
                                                    <span >{data.user_phone}</span>:
                                                    <span>
                                                    <NavLink to="/login">登录</NavLink>
                                                    后可查看
                                                </span>
                                            }
                                        </td>

                                    {/*<tr>*/}
                                    {/*    <th>联系方式</th>*/}
                                    {/*    <td>*/}
                                    {/*        {*/}
                                    {/*            isLogin?*/}
                                    {/*                <a onClick={ info } >点击查看</a>:*/}
                                    {/*                <span>*/}
                                    {/*                <NavLink to="/login">登录</NavLink>*/}
                                    {/*                后可查看*/}
                                    {/*            </span>*/}
                                    {/*        }*/}
                                    {/*    </td>*/}
                                    {/*    <th></th>*/}
                                    {/*    <td></td>*/}

                                    {/*</tr>*/}

                                    </tbody>
                                </table>
                            </div>
                            <div className={ css.viewCon } dangerouslySetInnerHTML={{__html:data.content}} />
                            <Descriptions style={{marginTop: '20px'}} column={1}>
                                {data.file.length >0 &&  <Descriptions.Item label='附件'>
                                    {  data.file.map((item)=>
                                        <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                    )}

                                </Descriptions.Item>}
                                {data.images.length>0 &&

                                <Descriptions.Item label='图片'>
                                    <UploadImage disabled={true} uploadNum={3}
                                                 value={data.images}/>
                                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                    </Modal>
                                </Descriptions.Item> }
                            </Descriptions>

                        </div>
                    </div>
                </div>
                </Fragment>
            }
        </div>
    )
}