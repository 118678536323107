import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb, CardBox, DefaultList, YcLoading} from '../../components';
import {Button, message} from 'antd';
import Icon, {DownloadOutlined, HeartFilled, HeartOutlined} from '@ant-design/icons';
// import FileViewer from 'react-file-viewer';
import css from './view.module.less';
import {useDataApi} from "../../hook/dataApi";
import {PublishModal} from "../../common";
import {tap} from "rxjs/operators";

export const DownloadPageView = (props) => {
    const {c_id: cId, id, } = props.location.state;
    let {push} = useHistory();
    const [{data, isLoading,setData},updateParams] = useDataApi({url: '/api/getNewsDetail', method: 'post', data: {id}});
    let [{data: hotList, isLoading: hotLoading}] = useDataApi({
        url: '/api/newsList',
        method: 'post',
        data: {c_id2: cId, type_id: 0, page: 1,flag:'hot', pagesize: 8}
    });

    useEffect(() => {
        if(!isLoading){
            updateParams({url: '/api/getNewsDetail', method: 'post', data: {id}});
        }
    }, [updateParams]);

    /** 显示更多 */
    const toMorePage = (type) => {
        if (type === 'hot') {
            push({pathname: '/category/148/178'});
        } else {
            push({pathname: '/category/148/178'});
        }
    };
    const toViewPage = (id) => {
        console.log('========', id);
        push('/category/148/177/view/'+id);
    };
    /** 收藏 */
    const handleCollect = ()=>{
        PublishModal.collect({id:data.id,type:5}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        PublishModal.cancelCollect({id:data.id,type:5}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };


    /** 增加下载数量 */
    const downloadAdd = () => {
        console.log("下载。。。。");
        PublishModal.downloadAdd({id:data.id}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{},
            error:err=>{},
            complete:()=>{}
        });

    }
    return (
        <div className="container">
            {data.title &&  <Breadcrumb list={ [{name:'下载专区',path:'/download?c_id=' + cId},{name:data.title}] } />}
            <div className={css.innerMain}>

                <div className={css.listWrap}>
                    <div className={css.viewWrap}>
                        <div className={css.titleInfo}>
                            <h2 className={css.viewTitle}>{ data.title }</h2>
                            <div className={css.titleInfoWarp}>
                                <div><Icon type="read"/><span>{data.read_num}人阅读</span></div>
                                <div><Icon type="download" style={{color: '#88AE93'}}/><span>{data.download_num}次下载</span></div>
                            </div>
                        </div>
                        {/*<div className={css.desInfo} >*/}
                        {/*    {data.file && <FileViewer fileType={ data.file.substr(data.file.lastIndexOf(".") + 1) }*/}
                        {/*                             filePath={ data.file }*/}
                        {/*                             onError={onError}*/}
                        {/*    />}*/}
                        {/*</div>*/}
                        <iframe src={data.file+'#toolbar=0'} frameBorder="0" width={'784px'} height={'696px'}/>

                        <div className={css.btns}>
                            {
                                data.is_collect ?  <Button type="primary" onClick={handleCancelCollect} icon={<HeartFilled />} size='large'>取消收藏</Button> :<Button type="primary" ghost onClick={handleCollect} icon={<HeartOutlined />} size='large'>我要收藏</Button>
                            }

                            <Button href={data.sourcefile} onClick={downloadAdd} download icon={<DownloadOutlined />} size='large' type="primary" ghost style={{width: '210px'}}>我要下载</Button>
                        </div>
                    </div>
                </div>

                <div className={css.sideBar}>
                    <CardBox title="热点新闻" more={() => {
                        toMorePage('hot')
                    }}>
                        {hotLoading ? <YcLoading/> : <DefaultList items={hotList.data} goDetail={toViewPage}/>}
                    </CardBox>
                    <div style={{marginBottom: 20}}/>
                    {/*<CardBox title="项目推荐" more={() => {*/}
                    {/*    toMorePage('commend')*/}
                    {/*}}>*/}
                    {/*    {commendLoading ? <YcLoading/> :*/}
                    {/*        <DefaultList items={commendList.data} goDetail={toViewPage}/>}*/}
                    {/*</CardBox>*/}
                </div>
            </div>
        </div>
    )
}