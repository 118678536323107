import React, {useEffect, useState} from 'react';
import css from './basic-data-ready.module.less';
import {Anchor, Button, Col, Form, Modal, Row, Table, TreeSelect} from 'antd';
import {useHistory} from "react-router-dom";
import {resourcesUrl} from "../../../../common/static_number";
import {useUserInfo} from "../../../../hook";
import moment from "moment";

const {Link} = Anchor;
const layout = {
    labelCol:{ span: 12 },
    wrapperCol:{ span: 14 }
}
const contentLayout = {
    labelCol:{ span: 10 },
    wrapperCol:{ span: 14 }
}
export const BasicDataReady  = (props)=>{
    const {data} = props;
    const {push} = useHistory();
    const [userInfo,setData,updateInfo] = useUserInfo();
    const [modalFlag,setModalFlag] = useState('');
    const [modalData,setModalData] = useState({});
    const [initData,setInitData] = useState({});
    const [form] = Form.useForm();
    const [educationList,setEducationList] = useState([])
    const [jobList,setJobList] = useState([])
    const [professionalList,setProfessionalList] = useState([]);
    const [avoidList,setAvoidList] = useState([]);
    const [qualificationList,setQualificationList] = useState([]);
    const [otherList,setOtherList] = useState([]);

    useEffect(()=>{
        form.resetFields();
    },[modalFlag]);
    useEffect(()=>{
        let _data = {};
        if(_data.status !== 0){
            _data = {...data};
            setEducationList(data.expert_education);
            setJobList(data.expert_work);
            setProfessionalList(data.expert_title);
            setQualificationList(data.expert_practice);
            setAvoidList(data.expert_back);
            setOtherList(data.expert_other);
        }
        _data.isLoading = true;
        setInitData({..._data})
        updateInfo(true);
    },[data]);

    const educationColumns = [
        {
            title: '最高学历',
            dataIndex: 'highest',
            key: 'highest',
            align: 'center',
        },
        {
            title: '毕业学校',
            dataIndex: 'school',
            key: 'school',
            align: 'center',
        },
        {
            title: '主修专业',
            dataIndex: 'major',
            key: 'major',
            align: 'center',
        },
        {
            title: '毕业时间',
            dataIndex: 'endtime',
            key: 'endtime',
            align: 'center',
        },
        {
            title: '学历证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                console.log("text",text)
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('education',{index,...record})}>查看</span>
                    </div>
                )

            }
        },

    ];
    const jobColumns = [
        {
            title: '单位名称',
            dataIndex: 'company',
            key: 'company',
            align: 'center',
        },
        {
            title: '工作内容',
            dataIndex: 'content',
            key: 'content',
            align: 'center',

        },
        {
            title: '开始时间',
            dataIndex: 'starttime',
            key: 'starttime',
            align: 'center',

        },
        {
            title: '结束时间',
            dataIndex: 'endtime',
            key: 'endtime',
            align: 'center',

        },
        {
            title: '工作状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',

            render: (text, record,index) =>{
                return  (
                    <span>
                        {{1:'在职',2:'离职',3:'兼职',4:'退休'}[text]}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('job',{index,...record})}>查看</span>
                    </div>
                )

            }
        },

    ];
    const professionalColumns = [
        {
            title: '职称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '发证单位',
            dataIndex: 'certification_unit',
            key: 'certification_unit',
            align: 'center',
        },
        {
            title: '证书编号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
        },
        {
            title: '发证时间',
            dataIndex: 'issuing_time',
            key: 'issuing_time',
            align: 'center',
        },
        {
            title: '证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('professional',{index,...record})}>查看</span>
                    </div>
                )

            }
        },

    ];
    const qualificationColumns = [
        {
            title: '执业资格',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '发证单位',
            dataIndex: 'certification_unit',
            key: 'certification_unit',
            align: 'center',

        },
        {
            title: '证书编号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
        },
        {
            title: '发证时间',
            dataIndex: 'issuing_time',
            key: 'issuing_time',
            align: 'center',

        },
        {
            title: '证书扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',

            render: (text, record,index) =>{
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('qualification',{index,...record})}>查看</span>
                    </div>
                )

            }
        },

    ];
    const avoidColumns = [
        {
            title: '单位名称',
            dataIndex: 'store_name',
            key: 'store_name',
            align: 'center',
            width: 300,

        },
        {
            title: '原因',
            dataIndex: 'remark',
            key: 'remark',

            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <div  className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('avoid',{index,...record})}>查看</span>
                    </div>
                )

            }
        },


    ];
    const otherColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '说明',
            dataIndex: 'remark',
            key: 'remark',
            width: 300,
            align: 'center',
        },
        {
            title: '其他材料扫描件',
            dataIndex: 'certificate',
            key: 'certificate',
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>
                        { text && text.map((item)=><img key={item} src={ resourcesUrl + item.file} alt="" style={{width:'50px',height:'50px'}}/>)}
                    </span>
                )

            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            width: 220,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <div className={css.options}>
                        <span className={css.edit} onClick={()=>showModal('other',{index,...record})}>查看</span>
                    </div>
                )

            }
        },

    ];

    const showModal = (key,data,index)=>{
        if(data){
            setModalData({index,...data});
        }else{
            setModalData({});
        }
        setModalFlag(key);

    };

    const updateData = ()=>{
        push('/expert/reg/1/'+ initData.type)
    }

    return (
        <div className={ css.basicData }>
            {/*//0=未认证，1=申请中，2=审核通过,3=审核未通过*/}
            { userInfo && userInfo.status === 0 && <div className={css.header} >
                <div>专家认证信息状态： <span className={css.status}>未认证</span></div>
                <div>注册时间： {moment(userInfo.createtime * 1000).format('YYYY-MM-DD')}</div>
                <div className={css.btn} onClick={updateData}>去认证</div>
            </div>}
            {
                userInfo && userInfo.status === 1 && <div className={css.header} >
                    <div>专家认证信息状态： <span className={css.status}>申请中</span></div>
                    <div>注册时间： {moment(userInfo.createtime * 1000).format('YYYY-MM-DD')}</div>
                    <div className={css.btn} onClick={updateData}>修改资料</div>
                </div>
            }
            {
                userInfo && userInfo.status === 2 && <div className={css.header} >
                    <div>专家认证信息状态： <span className={css.status}>认证通过</span></div>
                    <div>注册时间： {moment(userInfo.createtime * 1000).format('YYYY-MM-DD')}</div>
                    <div className={css.btn} onClick={updateData}>修改资料</div>
                </div>
            }
            {
                userInfo && userInfo.status === 3 && <div className={css.header} >
                    <div>专家认证信息状态： <span className={css.status}>认证未通过</span></div>
                    <div>注册时间： {moment(userInfo.createtime * 1000).format('YYYY-MM-DD')}</div>
                    <div className={css.btn} onClick={updateData}>修改资料</div>
                </div>
            }
            <Anchor targetOffset={30} style={{margin: '10px 0',padding: '6px 20px 6px 0'}}>
                <Link href="#basic" title="基本信息"/>
                {initData.type  === 3 && <Link href="#evaluation" title="评审信息"/>}
                <Link href="#education" title="学历信息"/>
                <Link href="#job" title="工作履历"/>
                <Link href="#professional" title="职称信息"/>
                <Link href="#qualification" title="职业资格"/>
                {initData.type  === 3 && <Link href="#avoid" title="回避信息"/>}
                <Link href="#other" title="其他材料"/>
            </Anchor>
            {
                initData.isLoading &&  <Form {...contentLayout} >
                    <div id={'basic'} className={css.boxWarp}>
                        <div className={css.warpTitle}>一、基本信息</div>
                        <div className={css.warpContent}>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'用户名'} name={'username'}
                                               rules={[{required: true, message: '请输入姓名'}]}>
                                        {initData.username}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'个人照片'}  wrapperCol={{span: 10}}>
                                        {initData.legal_identity_cert && initData.legal_identity_cert.map((opt)=>
                                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'性别'}>
                                        {{0:'男',1:'女'}[initData.gender]}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'出生日期'} >
                                        {initData.birthday}
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'身份证号码'}>
                                        {initData.legal_identity}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'身份证扫描件'}>
                                        {initData.legal_identity_cert && initData.legal_identity_cert.map((opt)=>
                                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'政治面貌'} >
                                        {initData.political}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'常驻地址'}>
                                        {initData.address}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'手机号码'}>
                                        {initData.mobile}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'电子邮箱'}>
                                        {initData.email}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={12} className={css.col}>
                                    <Form.Item label={'邮政编码'}>
                                        {initData.postal_ode}
                                    </Form.Item>
                                </Col>
                                <Col span={12} className={css.col}>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    { initData.type === 3 && <div id={'evaluation'} className={css.boxWarp}>
                        <div className={css.warpTitle}>二、评审信息</div>
                        <div className={css.warpContent}>
                            <Row className={css.row}>
                                <Col span={24} className={css.col}>
                                    <Form.Item label={'意愿评标地区'} labelCol={{ span: 7 }}>
                                       {initData.bidaddress}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className={css.row}>
                                <Col span={24} className={css.col}>
                                    <Form.Item label={'评标行业'} labelCol={{ span: 7 }}>
                                        {initData.industry && initData.industry.map((item,index)=>{
                                            return <span> {index ? '、':''} {item.industry_type_name} - {item.industry_id_name}&nbsp;&nbsp;</span>
                                        })}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    }
                    <div id={'education'} className={css.boxWarp}>
                        <div className={css.warpTitle}>{initData.type === 3 ?'三':'二'}、学历信息</div>
                        <Table dataSource={educationList} columns={educationColumns} pagination={false} bordered/>
                    </div>

                    <div id={'job'} className={css.boxWarp}>
                        <div className={css.warpTitle}>{initData.type === 3 ?'四':'三'}、工作履历</div>
                        <Table dataSource={jobList} columns={jobColumns}  pagination={false} bordered/>
                    </div>

                    <div id={'professional'} className={css.boxWarp}>
                        <div className={css.warpTitle}>{initData.type === 3 ?'五':'四'} 、职称信息</div>
                        <Table dataSource={professionalList} columns={professionalColumns}  pagination={false} bordered/>
                    </div>

                    <div id={'qualification'} className={css.boxWarp}>
                        <div className={css.warpTitle}>{initData.type === 3 ?'六':'五'}、职业资格</div>
                        <Table dataSource={qualificationList} columns={qualificationColumns}  pagination={false} bordered/>
                    </div>

                    {initData.type  === 3 && <div id={'avoid'} className={css.boxWarp}>
                        <div className={css.warpTitle}>七、回避信息</div>
                        <Table dataSource={avoidList} columns={avoidColumns}  pagination={false} bordered/>
                    </div>}
                    <div id={'other'} className={css.boxWarp}>
                        <div className={css.warpTitle}>{initData.type  === 3?  '八':'六'}、其他材料</div>
                        <Table dataSource={otherList} columns={otherColumns}  pagination={false} bordered/>
                    </div>
                </Form>
            }

            <Modal  title="学历信息"
                    visible={ modalFlag === 'education' }
                    centered
                    footer={null}
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
            >
                <Form
                   {...layout}
                >
                    <Form.Item label="最高学历">
                        {modalData.highest}
                    </Form.Item>
                    <Form.Item label='毕业时间'>
                        {modalData.endtime}
                    </Form.Item>
                    <Form.Item label='毕业院校'>
                        {modalData.school}
                    </Form.Item>
                    <Form.Item label={'主修专业'}>
                        {modalData.major}
                    </Form.Item>
                    <Form.Item label={'学历证书扫描件'}>
                        {modalData.certificate && modalData.certificate.map((opt)=>
                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                        )}
                    </Form.Item>

                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>

            <Modal  title="工作履历"
                    visible={ modalFlag === 'job' }
                    centered
                    footer={null}
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
            >
                <Form
                    {...layout}
                >
                    <Form.Item
                        label="单位名称"
                    >
                        {modalData.company}
                    </Form.Item>
                    <Form.Item label="工作状态">
                        {{'1':'在职','2':'离职','3':'兼职','4':'退休'}[modalData.status]}
                    </Form.Item>
                    <Form.Item label='工作开始时间' >
                        {modalData.starttime}
                    </Form.Item>
                    <Form.Item label='工作结束时间' >
                        {modalData.endtime}
                    </Form.Item>
                    <Form.Item label='单位地址' >
                        {modalData.address}
                    </Form.Item>
                    <Form.Item label={'所属行业'}>
                        {modalData.industry}
                    </Form.Item>
                    <Form.Item label={'单位级别'}>
                        {modalData.level}
                    </Form.Item>
                    <Form.Item label={'工作内容'}>
                        {modalData.content}
                    </Form.Item>
                    <Form.Item label={'工作证明扫描件'}>
                        {modalData.certificate && modalData.certificate.map((opt)=>
                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                        )}
                    </Form.Item>
                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="职称信息"
                    visible={ modalFlag === 'professional' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    {...layout}
                >
                    <Form.Item label="职称">
                        {modalData.name}
                    </Form.Item>
                    <Form.Item label='证书编号'>
                        {modalData.number}
                    </Form.Item>
                    <Form.Item label='发证单位'>
                        {modalData.certification_unit}
                    </Form.Item>
                    <Form.Item label={'发证时间'} >
                        {modalData.issuing_time}
                    </Form.Item>
                    <Form.Item label={'证书扫描件'}>
                        {modalData.certificate && modalData.certificate.map((opt)=>
                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                        )}
                    </Form.Item>
                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="职业资格"
                    visible={ modalFlag === 'qualification' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    {...layout}
                >
                    <Form.Item label='职业资格'>
                        {modalData.name}
                    </Form.Item>
                    <Form.Item label='证书编号'>
                        {modalData.number}
                    </Form.Item>
                    <Form.Item label='发证单位'>
                        {modalData.certification_unit}
                    </Form.Item>
                    <Form.Item label={'发证时间'}>
                        {modalData.issuing_time}
                    </Form.Item>
                    <Form.Item label={'证书扫描件'}>
                        {modalData.certificate && modalData.certificate.map((opt)=>
                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                        )}
                    </Form.Item>
                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="回避信息"
                    visible={ modalFlag === 'avoid' }
                    centered
                    destroyOnClose
                    forceRender
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    {...layout}
                >
                    <Form.Item label='单位名称'>
                        {modalData.store_name}
                    </Form.Item>
                    <Form.Item label='回避原因'>
                        {modalData.remark}
                    </Form.Item>
                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>
            <Modal  title="其他材料"
                    visible={ modalFlag === 'other' }
                    centered
                    destroyOnClose
                    onCancel={()=>setModalFlag('')}
                    footer={null}
            >
                <Form
                    {...layout}
                >
                    <Form.Item label='名称'>
                        {modalData.name}
                    </Form.Item>
                    <Form.Item label='说明'>
                        {modalData.remark}
                    </Form.Item>
                    <Form.Item label={'其他材料扫描件'}>
                        {modalData.certificate && modalData.certificate.map((opt)=>
                            <img src={resourcesUrl +  opt.file} alt="" className={css.img}/>
                        )}
                    </Form.Item>
                    <div className={css.btns}>
                        <Button onClick={()=>setModalFlag('')}>确定</Button>
                    </div>
                </Form>
            </Modal>
        </div>

    )
}