import React, {useEffect, useState} from "react";
import css from "./answer.module.less";
import {Button, Descriptions, Form, message, Modal, Select, Table, TreeSelect} from 'antd';
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";
import {AddressItem} from "../../../../components";
import {ExpertModal as ExpertModel, PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";
import {ExpertDetail} from "../../../expert/detail";

const {Option} = Select;
/**
 *  抽取专家规则
 * */
export const ExpertRule = (props) => {
    const {params:{project_sn=''} } = props;
    const [initialData, setInitialData] = useState({});
    const [{data:professionList}] = useDataApi({url: '/api/gettitles', method: 'post',initialData:[]});
    const [{data,setData, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const [industryList, setIndustryList] = useState([]);
    const [{data:industry,isLoading:industryLoading }] = useDataApi({url: '/api/getAddCategroy', method: 'post', data: { project_type: 1, type: 1 },initialData: [] });
    const [valueMap] = useState({});
    const [params,setParams] = useState();
    const [ruleModal,setRuleModal] = useState(false);
    const [expertModal,setExpertModal] = useState({flag:false});
    useEffect( () => {
        if(!industryLoading){
            const _industry= industry.map(item =>{
                if(item.son.length){
                    const children =   item.son.map(child =>{
                        return { title: child.name, value: child.value,}
                    })
                    return { title: item.name, value: item.value,children,}
                }else{
                    return { title: item.name, value: item.value}
                }
            });
            console.log("_industry",_industry);
            setIndustryList(_industry);
            loops(_industry);
        }
    }, [industryLoading]);

    let { push } = useHistory();
    const [ruleForm] = Form.useForm();
    const [{data: dataSource},updateParams] = useDataApi({initialData: []});// 获取公告中已选的供应商
    useEffect(()=>{
        updateParams({url: '/api/expertlist', method: 'post',data: {project_sn}, initialData: []})
    },[params]);

    useEffect(() => {
        if (!isLoading) {
            let _data = data.project_issued;
            _data.isLoading = true;
            _data.time = Date.now();
            setInitialData(_data);
        }
    }, [isLoading]);
    const columns = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }
        },
        {
            title: '专家名称',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '专家职称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '专业类型',
            key: 'type',
            dataIndex: 'type',
            render: (text,record) => {
                return <span>{record.industry_type} - {record.industry_id}</span>
            },
        },
        {
            title: '专业工龄',
            key: 'working_years',
            dataIndex: 'working_years',
            render: text => {
                return <span>{text}年</span>
            },
        },

        {
            title: '评标地域',
            key: 'address',
            dataIndex: 'address',
        },
        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            render: (text,record) => {
                switch (text) {
                    case 0:
                        return <span>未邀请</span>
                    case 1:
                        return <span>已邀请</span>
                    case 2:
                        return <span>已接受</span>
                    case 3:
                        return <span>已拒绝</span>
                    case 4:
                        return <span>已结束</span>
                    case 5:
                        return <span>已完成</span>
                    default: return
                }
            }
        },{
            title: '操作',
            key: 'detail',
            dataIndex: 'detail',
            render: (text,record) => {
                return <a onClick={()=>setExpertModal({flag:true,expert_id:record.expert_id,project_sn})}>查看</a>;

            },
        }
    ];

    const submit = ()=>{
        PublishModal.validateOpen({project_sn}).pipe().subscribe({
            next:res=>{
                push({pathname: '/publish/listOpen',state:{project_sn:initialData.project_sn,step:1}});
            },
            error: err=>{
                console.log("err",err);
            }
        })
    };

    const ruleSubmit = (data)=>{
        let _data  = data ||{};
        const [industry_type,industry_id] = getPath(_data.industry);
        const [province_id,city_id,county_id] = _data.address;
        const {id:title_id,name:title} = professionList[_data.titleIndex];
        ExpertModel.setRules({project_sn,industry_type,industry_id,province_id,city_id,county_id,title,title_id,..._data}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
               message.success('抽取成功');
               setRuleModal(false);
               setParams({})
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    function loops(list, parent) {
        return (list || []).map(({ children, value }) => {
            const node = (valueMap[value] = {
                parent,
                value
            });
            node.children = loops(children, node);
            return node;
        });
    }
    function getPath(value) {
        const path = [];
        let current = valueMap[value];
        while (current) {
            path.unshift(current.value);
            current = current.parent;
        }
        return path;
    }

    /** 邀请成功回调 */
    const inviteChange = ()=>{
        setParams({})
    };

    /** 抽取人数改变时间 */
    const numChange = (v)=>{
        console.log("v",v);
        let _data = data;
        _data.project_issued.number = v;
        setData(_data);
    };

    return (
        <div className={css.answer}>
            <div className={css.boxTitle}>
                设置专家抽取规则
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>

            <Descriptions column={1}>
                <Descriptions.Item label="抽取时间">
                    {initialData.bidstart_time} ~ {initialData.bidend_time}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions >
                <Descriptions.Item>候选专家列表</Descriptions.Item>
                <Descriptions.Item label="" style={{textAlign: 'right'}}>
                    <Button className={css.lookBtn}  style={{marginRight:'15px'}} onClick={()=>push({pathname: '/publish/listSend',state:{project_sn,step:4}})}>查看投标情况</Button>
                    <Button type={"primary"} onClick={()=>setRuleModal(true)}>设置抽取规则</Button>
                </Descriptions.Item>
            </Descriptions>
            <Table columns={columns} pagination={false} dataSource={dataSource} bordered />
            <div className={css.btns}>
                <Button type="primary" size={"large"} onClick={ submit } disabled={(initialData.down_time === 0)}>
                    进入开标大厅
                </Button>
            </div>
            {
                !isLoading &&  <Modal  title="设置抽取规则"
                                      visible={ ruleModal }
                                      centered
                                      footer={null}
                                      destroyOnClose
                                      okText={'提交'}
                                      onCancel={()=>setRuleModal(false)}
                >
                    <Form form={ruleForm}
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 14 }}
                          onFinish={ruleSubmit}
                    >
                        <Form.Item label='抽取人数' name={'num'} initialValue={data.project_issued.number} rules={[{required: true, message: '请填写抽取人数'}]}>
                            <Select  placeholder="请选择抽取人数" disabled={data.project_issued.number} onChange={numChange}>
                                <Option value="2">2</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                                <Option value="7">7</Option>
                                <Option value="8">8</Option>
                                <Option value="9">9</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='抽取地区'  name="address" rules={[{required:true,message:'请选择抽取地区'}]}>
                            <AddressItem size="default" />
                        </Form.Item>
                        <Form.Item label='抽取行业' name={'industry'}  rules={[{required: true, message: '请填写手机号'}]}>
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={industryList}
                                placeholder="请选择所属行业"
                            />
                        </Form.Item>
                        <Form.Item label='职称要求' name={'titleIndex'}  rules={[{required: true, message: '请填写职称要求'}]}>
                            <Select  placeholder="请选择职称要求" >
                                {professionList.map((opt,index)=>
                                    <Option value={index} key={opt.id}>{opt.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label='专业工龄' name={'working_years'}  rules={[{required: true, message: '请选择专业工龄'}]}>
                            <Select  placeholder="请选择专业工龄" >
                                <Option value="1">五年以上</Option>
                                <Option value="2">八年以上</Option>
                            </Select>
                        </Form.Item>
                        <div className={css.footer}>
                            <Button type={"primary"} htmlType={'submit'}>确定</Button>
                        </div>
                    </Form>
                </Modal>
            }

            <Modal  title="专家信息"
                    visible={ expertModal.flag }
                    centered
                    footer={null}
                    destroyOnClose
                    width={'800px'}
                    onCancel={()=>setExpertModal({flag:false})}>
                <ExpertDetail data={expertModal} callback={inviteChange}/>

            </Modal>
        </div>);
}