import React, {Fragment} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {EmptyBox} from '../../components';
import {Login} from "./login";
import {Recover} from "./login/recover";
import {Setting} from "./mine/setting";
import {Message} from "./mine/message";
import {ProComment, ProInvite} from "./mine/project";
import {ExpertDetail} from "./detail";
import {Password} from "./mine/setting/password";
import {Mobile} from "./mine/setting/mobile";
import {Marker} from "./mine/marker";
import {ExpertMarket} from "./detail/market";
import {Footer, UserInfo} from "./components";
import {Reg} from "./login/reg";
import {Header} from "../common/header";
import {RegBidIndex} from "./login/bid/reg-index";
import {RegIndustryIndex} from "./login/industry/reg-index";
import css from "./index.module.less";
import {SubNav} from "../../router/sub-nav";
import {ExpertTopHeader} from "./components/top-header";
import {TenderDetail} from "../detail/tenderDetail";

export const Expert = ()=>{

    let { push } = useHistory();
    return (
        <Fragment>
            {/*<ExpertTopHeader/>*/}
            <Header key={new Date()}/>

            <Switch>
                    <Redirect exact from="/expert" to="/expert/login"/>
                    <Route exact path="/expert/login" component={Login}/>
                    <Route exact path={["/expert/recover","/expert/recover/:type"]} component={ Recover }/>
                    <Route exact path="/expert/reg/bid/index" component={RegBidIndex} />
                    <Route exact path="/expert/reg/industry/index" component={RegIndustryIndex} />
                    <Route exact path={ ['/expert/reg','/expert/reg/:step','/expert/reg/:step/:type' ] } component={ Reg }/>
                    <div className="container">
                        <div className={css.body}>
                            <div className={css.sideBar}>
                                <UserInfo/>
                                <SubNav/>
                            </div>
                            <div className={css.mainBody} style={{minHeight: 'calc(100vh - 124px - 41px)'}}>
                                <Switch>
                                    <Route exact path="/expert/detail" component={ ExpertDetail }/>
                                    <Route exact path="/expert/setting" component={ Setting }/>
                                    <Route exact path="/expert/setting/mobile" component={ Mobile }/>
                                    <Route exact path="/expert/setting/password" component={ Password }/>
                                    <Route exact path="/expert/message" component={ Message }/>
                                    <Route exact path="/expert/comment" component={ ProComment }/>
                                    <Route exact path="/expert/invite" component={ ProInvite }/>
                                    <Route exact path="/expert/marker" component={ Marker }/>
                                    <Route exact path="/expert/market/view" component={ ExpertMarket }/>
                                    <Route exact path="/expert/detail/tenderDetail" component={TenderDetail}/>
                                    <Route key="error" render={(props) => <EmptyBox/>}/>
                                </Switch>
                            </div>
                        </div>
                    </div>

                    }

                </Switch>
            <Footer />

        </Fragment>
    );
}