import React, {Fragment, useState} from 'react';
// import { useHistory } from 'react-router-dom';
import {IndexLogo} from '../../components';
import {NavLink, useHistory} from 'react-router-dom';

import {Dropdown, Menu, Modal} from 'antd';
import classNames from 'classnames';
import css from './index.module.less';
import {MASTER_URL} from "../../common/static_number";
import {useIsLogin, useUserInfo} from "../../hook";
import {ServicesModel} from "../../common";
import {store} from "../../helps";
import avatar from 'aliasimgurl/default_avatar.png';

import {CaretDownOutlined, EnvironmentFilled, PhoneFilled} from '@ant-design/icons';


export const Header = ()=>{

    // 回到首页 | 快速注册 | 帮助中心
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();
    const [visible,setVisible] = useState();

    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                Modal.success({
                    title:'退出成功！'
                });
                window.location.href = '/expert';
            },
            error:err=>{},
            complete:()=>{
                store.clearAll();
            }
        });
    }
    const onClick = ({key})=>{
        console.log("key",key);
        push(key);
    }
    const menu = (
        <Menu onClick={onClick} className={css.items}>
            <Menu.Item key="/expert/setting" className={css.item}>账户设置</Menu.Item>
            <Menu.Item key="/expert/message" className={css.item}>消息通知</Menu.Item>
            <Menu.Item key="/expert/invite" className={css.item}>项目邀请</Menu.Item>
            <Menu.Item key="/expert/comment" className={css.item}>项目评分</Menu.Item>
        </Menu>
    );
    const {push} = useHistory();

    return (
        <div className={ css.header }>
            <div className={ classNames(css.headerWrap,"container") }>
                <div className={ css.logo } >
                    <IndexLogo  />
                </div>
                <div className={ css.topMenu }>
                    <a href={MASTER_URL}>返回主站</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    {
                        isLogin?
                            <Fragment>
                                <a className={css.userinfo} href={'/expert/setting'}>
                                    <img src={data.avatar || avatar} alt=""/>
                                    <span>{data.mobile}</span>
                                </a>
                                &nbsp;&nbsp;
                                <Dropdown overlay={menu} onVisibleChange={visible => setVisible(visible)} placement={'bottomCenter'}>
                                    {/*<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                                    {/*    Hover me, Click menu item <DownOutlined />*/}
                                    {/*</a>*/}
                                    <a style={{color: visible?'#645ECC':'#150A07'}}>我的账户 <CaretDownOutlined /></a>
                                </Dropdown>
                                <i></i>
                                <a onClick={ handleLogout }>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login">请登录</NavLink>
                            </Fragment>
                    }
                </div>
            </div>
        </div>

    )
}