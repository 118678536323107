import React from 'react';
import css from './footer.module.less';
import ga from 'aliasimgurl/ga.png';

export const Footer = ()=>{
    return (
        <div className={ css.footer }>
            <div className={ css.footerBottom }>
                <div>
                    © 2022 版权所有 昆明农村产权流转服务平台  滇ICP备12003055号-2
                </div>
                {/*<div>*/}
                    {/*<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004231"><img src={ga} /><span>京公网安备 11010102004231号</span></ a>*/}
                {/*</div>*/}
              {/*  <div>
                    运营服务：北京中镇生产力科技中心 中镇枫林规划设计研究院
                </div>
                <div>
                    技术支持：中镇生态科技河北有限公司
                </div>*/}
            </div>
        </div>
    );
}
