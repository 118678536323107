import React, {Fragment, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {useEffectOnce} from 'react-use';
import {Dropdown, Menu, Modal} from 'antd';
import {CaretDownOutlined, EnvironmentFilled, PhoneFilled} from '@ant-design/icons';
import {useIsLogin, useUserInfo} from '../hook';
import {ServicesModel} from '../common';
import {store} from '../helps';
import css from './top-header.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import {MASTER_URL} from "../common/static_number";

export const TopHeader = (props)=>{
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();
    const [city,setCity] = useState('加载中...');
    const [timer,setTimer] = useState(null);
    const [visible,setVisible] = useState();
    const [userInfo] = useUserInfo();
    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                Modal.success({
                    title:'退出成功！'
                });
                window.location.href = '/';
            },
            error:err=>{},
            complete:()=>{
                console.log('doLogout')
                store.clearAll();
            }
        });
    }
    useEffectOnce(()=>{
        function updateCity() {
            let _city = window.currentCity;
            setCity(_city||'加载中...');
            if (!_city) {
                let _timer = setTimeout(()=>{
                    updateCity();
                },1000);
                setTimer(_timer);
            };
        };
        updateCity();
        return ()=>{
            if (timer) {
                clearInterval(timer);
            }
        }
    });
    const {push} = useHistory();
    const onClick = ({key})=>{
        console.log("key",key);
        push(key);
    }
    const menu = (
        <Menu onClick={onClick} className={css.items}>
            <Menu.Item key="/account/setting" className={css.item}>账户设置</Menu.Item>
            <Menu.Item key="/account/work" className={css.item}>消息通知</Menu.Item>
            <Menu.Item key="/account/project" className={css.item}>项目管理</Menu.Item>
            <Menu.Item key="/account/deposit" className={css.item}>保证金</Menu.Item>
            <Menu.Item key="/account/collect" className={css.item}>收藏夹</Menu.Item>
        </Menu>
    );
    return (
        <div className={ css['top-header'] }>
            <div className="container">
                <div className={ css.wraper }>
                    <div className={ css.welcome }>
                        <div>
                            <EnvironmentFilled /> {city.replace('市','')}
                        </div>
                        <div>
                            <PhoneFilled /> 131-2477-0057
                        </div>
                    </div>
                    <div className={ css.ext }>
                        {
                            isLogin?
                            <Fragment>
                                <a className={css.userinfo} href={'/account/setting'}>
                                    <img src={data.avatar || avatar} alt=""/> {data.mobile} &nbsp;&nbsp;
                                </a>

                                <div className={css.menu}>
                                    <a href={MASTER_URL + '/account/home'} className={css.menuTitle}>个人中心 <CaretDownOutlined /></a>
                                    <div className={css.menuBox}>
                                        <a href={MASTER_URL + "/account/setting"}>账户设置</a>
                                        <a href={MASTER_URL + "/account/work"}>消息通知</a>
                                        {/*<a href={"/account/project"}>林地管理</a>*/}
                                        {/*<a href={TENDER_URL + "/account/project"} target={"_block"}>招投标管理</a>*/}
                                        {/*<a href={PROJECT_URL + "/account/project"} target={"_block"}>项目库管理</a>*/}
                                        {/*<a href={"/account/collect"}>收藏夹</a>*/}
                                    </div>
                                </div>
                                <i></i>
                                <a onClick={ handleLogout }>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login">请登录</NavLink>

                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}