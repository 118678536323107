import React, {useEffect, useState} from "react";
import css from "./index.module.less";
import {Button, Descriptions} from 'antd';
import moment from "moment";
import {useHistory} from "react-router-dom";
import {AUDIT_SEND, MODE_OFF, resourcesUrl} from "../../../../../../common/static_number";
import {useDataApi} from "../../../../../../hook";

export const Bidding = (props) => {
    const {params:{project_sn=""}} = props;
    const [defaultFileList, setDefaultFileList] = useState([]); //默认的文件列表
    const [initialData, setInitialData] = useState({});

    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});
    let { push } = useHistory();
    useEffect(() => {
        if (!isLoading) {
            let _data = data;
            _data.project_issued = _data.project_issued ||{};
            _data.bidstart_time = _data.project_issued.bidstart_time ? moment(_data.project_issued.bidstart_time ):null;
            _data.bidend_time =_data.project_issued.bidend_time ? moment(_data.project_issued.bidend_time ):null;

            if (_data.project_issued.attachment_url.length) {
                setDefaultFileList(_data.project_issued.attachment_url)
            }
            _data.isLoading = true;
            setInitialData({..._data});
        }
    }, [isLoading]);
    const submit = ()=>{
        push({pathname:'/expert/marker',state:{project_sn,step:2}})
    };

    return (
        <div className={css.mainBody}>
                {!initialData.isLoading ||
                <div className={css.index}>
                    <div className={css.boxTitle}>
                        招标文件
                        <div className={css.info}>
                            <span >项目名称：</span>
                            <span className={css.high}>{data.project_name}</span>
                            <span >项目编号：</span>
                            <span className={css.high}>{data.project_sn}</span>
                        </div>
                    </div>
                    <Descriptions bordered column={2}>
                        <Descriptions.Item label="公告标题" span={2}>{initialData.title}</Descriptions.Item>
                        <Descriptions.Item label="项目名称">{initialData.project_name}</Descriptions.Item>
                        <Descriptions.Item label="项目编号">NO:{initialData.project_sn}</Descriptions.Item>
                        <Descriptions.Item label="行业分类">{initialData.category_trade}</Descriptions.Item>
                        <Descriptions.Item label="二级分类">{initialData.category_name}</Descriptions.Item>
                        <Descriptions.Item label="招标类型">{initialData.tentype}</Descriptions.Item>
                        <Descriptions.Item label="招标方式">{initialData.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标' }</Descriptions.Item>

                        <Descriptions.Item label="招标文件标题">{initialData.project_issued.issued_name}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions title="招标文件正文" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            {initialData.project_issued.content &&   <div dangerouslySetInnerHTML={{__html: initialData.project_issued.content}}/>}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions style={{marginTop: '20px'}} column={1}>
                        {defaultFileList.length >0 && <Descriptions.Item label='附件'>
                            {defaultFileList.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Descriptions.Item>}
                    </Descriptions>

                    <div className={css.btns}>
                        <Button size={"large"} className={css.saveBtn} onClick={submit}>
                            下一步
                        </Button>
                    </div>
                </div>}
            </div>
    );
}