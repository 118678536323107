import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom'
import {useEffectOnce} from 'react-use';
import {Breadcrumb, CardBox, UploadImage, YcImage,} from '../../components';
import {Button, Descriptions, message, Modal} from 'antd';
import headerImg from 'aliasimgurl/bidding_header.png';
import {useDataApi, useIsLogin} from '../../hook';
import css from './index.module.less';
import {PublishModal} from "../../common";
import {tap} from "rxjs/operators";
import {MODE_OFF, resourcesUrl} from "../../common/static_number";
import {ProjectList} from "../../components/project-list";
import classNames from 'classnames';


export const BidHallView = (props) => {

    const [isLogin] = useIsLogin();
    const {project_sn=''} = props.location.state;
    console.log("project_sn",project_sn);
    const [{data,isLoading,setData},updateParams] = useDataApi();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage] = useState(''); //默认的图片列表
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/projectDetail', method: 'post', data: {project_sn,type:2}})
    },[project_sn])
    const { push } = useHistory();

    /** 收藏 */
    const handleCollect = ()=>{
        PublishModal.collect({id:data.projectinfo.id,type:1}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.projectinfo.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        PublishModal.cancelCollect({id:data.projectinfo.id,type:1}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.projectinfo.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    const handleCancel = () => setPreviewVisible(false);

    const goDetail = () =>{
        if(isLogin){
            push({
                pathname: '/publish/commune',
                state: {project_sn: project_sn, status: data.status}
            })
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });

        }
    }

    return (
        <div className="container">
            <YcImage imgUrl={ headerImg } />
            {!isLoading && <Fragment>
                <Breadcrumb list={ [{name:'中标公示',path:'/bidhall'},{name:data.projectinfo.title||""}] } />
                <div className={ css.innerMain }>
                    <div className={ css.listWrap }>
                        <div className={ css.viewWrap }>
                            <div className={ css.titleInfo }>
                                <h2 className={ css.viewTitle }>{data.projectinfo.title}</h2>
                                <p>
                                    <i className="iconfont iconweizhi"/> <span>{data.projectinfo.address}</span>
                                    <i className="iconfont iconrili"/> <span>{data.projectinfo.releasetime}</span>
                                    {/*{*/}
                                    {/*    data.projectinfo.is_collect?  <Fragment><i className={classNames(css.check,"iconfont iconshoucangon ")}/><span onClick={handleCancelCollect} style={{cursor: "pointer"}} className={css.check}>已收藏</span></Fragment>:<Fragment><i className="iconfont iconshoucangon"/><span onClick={handleCollect} style={{cursor: "pointer"}}>收藏</span></Fragment>*/}
                                    {/*}*/}

                                    {/* <i className="iconfont iconfenxiang"></i>
                                <span>分享</span>
                                <i className="iconfont icondayin"></i>
                                <span>打印</span>*/}
                                </p>
                            </div>
                            <div className={ css.extInfo }>
                                <table>
                                    <tbody>
                                    <th>项目编号</th>
                                    <td>{data.projectinfo.project_sn}</td>
                                    <th>公告名称</th>
                                    <td >{data.projectinfo.title}</td>

                                    <th>项目名称</th>
                                    <td>{data.projectinfo.project_name}</td>
                                    <th>行业分类</th>
                                    <td>{data.projectinfo.industry_type}-{data.projectinfo.industry_id}</td>
                                    <th>招标单位</th>
                                    <td>{data.projectinfo.company_name}</td>
                                    <th>投标保证金</th>
                                    <td>
                                        {data.projectinfo.bond} (万元)
                                    </td>

                                    <th>招标类型</th>
                                    <td>{data.projectinfo.tendering_type ==='1' ?'公开招标':'邀请招标'}</td>
                                    <th>招标方式</th>
                                    <td>
                                        {data.projectinfo.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标' }
                                    </td>

                                    <th>联系人</th>
                                    <td>
                                        {data.projectinfo.user_name}
                                    </td>
                                    <th>联系电话</th>
                                    <td>
                                        {
                                            isLogin?
                                                <span >{data.projectinfo.user_phone}</span>:
                                                <span>
                                                    <NavLink to="/login">登录</NavLink>
                                                    后可查看
                                                </span>
                                        }
                                    </td>
                                    <th>所属地区</th>
                                    <td>{data.projectinfo.address}</td>
                                    <th></th>
                                    <td></td>
                                    {/*{data.projectinfo.infor_type ==='中标公示' &&  <Fragment>*/}
                                    {/*    <th>成交时间</th>*/}
                                    {/*    <td>{ data.projectinfo.completetime}</td>*/}
                                    {/*</Fragment>}*/}

                                    </tbody>
                                </table>
                            </div>
                            <div className={ css.viewCon } dangerouslySetInnerHTML={{__html:data.projectinfo.content}}/>
                            <Descriptions style={{marginTop: '20px'}} column={1}>
                                {data.projectinfo.file &&  <Descriptions.Item label='附件'>
                                    {  data.projectinfo.file.map((item)=>
                                        <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                    )}

                                </Descriptions.Item>}
                                {data.projectinfo.images &&

                                <Descriptions.Item label='图片'>
                                    <UploadImage disabled={true} uploadNum={3}
                                                 value={data.projectinfo.images ||[]}/>
                                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                    </Modal>
                                </Descriptions.Item> }
                            </Descriptions>
                        </div>
                    </div>
                    <div className={ css.sideBar }>
                        <div className={ css.sideInfo }>
                            {/*<div className={ css.btnWrap }>*/}
                            {/*    {*/}
                            {/*        data.projectinfo.sign_status === 1 &&  <Button type="primary" disabled>报名未开始</Button>*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        data.projectinfo.sign_status === 2 && <Button type="primary" onClick={goApply}>立即报名</Button>*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        data.projectinfo.sign_status === 3 && <Button type="primary" style={{cursor: "not-allowed"}}>已报名</Button>*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        data.projectinfo.sign_status === 4 &&  <Button disabled >报名已结束</Button>*/}
                            {/*    }*/}
                            {/*</div>*/}
                            {/*<div className={ css.btnWrap } style={{marginTop: '10px'}}>*/}
                            {/*    <Button type="primary" onClick={goDetail}>答疑专区</Button>*/}
                            {/*</div>*/}
                            <div className={ css.title }>时间汇总</div>
                            <div className={ css.sideInfoList }>
                                <div className={ css.item }>
                                    <div className={ css.hd }>报名时间</div>
                                    <div className={ css.bds }>开始：{data.projectinfo.signup_time}</div>
                                    <div className={ css.bde }>结束：{data.projectinfo.signend_time}</div>
                                </div>
                            </div>

                        </div>
                        <CardBox title="项目推荐" more={ ()=>push('/market')}>
                            <ProjectList  items={data.prolist} goDetail={(o)=>{console.log(o); push({pathname:'/market/view',state:{project_sn:o.project_sn}})}}/>
                        </CardBox>
                    </div>
                </div>
            </Fragment>}
        </div>
    )
}