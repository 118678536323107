import React from 'react';
import {Steps} from 'antd';
import css from './steps.module.less'
const { Step } = Steps;

export const LoginSteps = (props)=>{
    const {current=0} = props;
    return (

        <div style={{display:'flex'}} className={css.steps}>
            <Steps labelPlacement={"vertical"} current={Number.parseInt(current)}>
                <Step title="注册账号" />
                <Step title="完善资料" />
                <Step title="资料审核"/>
            </Steps>
        </div>
    )
}
