import React, {useEffect, useState,Fragment} from "react";
import css from "./index.module.less";
import {Button, Col, Form, message, Modal, Radio, Row} from 'antd';
import {createFromIconfontCN} from '@ant-design/icons';
import classNames from 'classnames'
import {useDataApi} from "../../../../hook/dataApi";
import {PublishModal} from '../../../../common/index';
import {
    MODE_OFF,
    MODE_ON,
    TYPE_BIDDING,
    TYPE_COMMON,
    TYPE_INVITE,
    TYPE_PROCUREMENT
} from '../../../../common/static_number'
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";
import {useIsLogin, useUserInfo} from '../../../../hook';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6tmts73qde.js',
});
const layout = {
    labelAlign: "left",
    labelCol: {span: 3},
    wrapperCol: {span: 10},
};
export const Index = (props) => {
    const [form] = Form.useForm();
    const [userInfo] = useUserInfo();
    const [isLogin] = useIsLogin();

    const [cate, setCate] = useState( 1);
    const [mode, setMode] = useState( 1);
    const [type , setType] = useState(1);
    const [trade, setTrade] = useState();
    const [sub, setSub] = useState();
    const [subList,setSubList] =useState([]);
    const {push} = useHistory();
    /** 提交事件 */
    const onsubmit = async () => {
        if(!isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
            return
        }
        if(userInfo.apply_state != 2 && userInfo.type != 3){
            Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title:'温馨提示',
                content: '您的账号尚未完成认证，可能会影响部分功能体验。',
                okText:'立即认证',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    push('/account/setting/userauth');
                },
                onCancel:()=>{

                }
            });
            return
        }
        if(!sub && !cate && !trade){
            message.error('请选择二级分类');
            return
        }
        PublishModal.createPro({type:cate,industry_type:trade,tendering_type: cate === TYPE_BIDDING ?type:'0',industry_id:sub,tendering_mode :mode}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:({data})=>{
                if(cate === TYPE_BIDDING){
                    if(mode === MODE_OFF){
                        push({pathname:'/publish/off',state:{step:1,project_sn:data.project_sn}});
                    } else{
                        push({pathname:'/publish',state:{step:2,project_sn:data.project_sn}});
                    }
                }else{
                    push({pathname:'/procurement',state:{step:1,project_sn:data.project_sn}});
                }
            },
            error:err=>{},
            complete:()=>{}
        });
        //
    };

    /** 项目类型改变事件 （招标 OR 采购） */
    const cateChange = cate => {
        setCate(cate.target.value);
    };

    /** 招标类型改变事件 （公开 OR 邀请） */
    const typeChange = (item)=>{
        setType(item.target.value);
    };

    /** 项目类型改变事件 （线上 OR 线下） */
    const modeChange = mode => {
        setMode(mode.target.value);
    };
    /** 行业分类改变事件 */
    const tradeChange = (id,item) => {
        setTrade(id);
        setSub('');
        setSubList(item.son)
    };

    /** 行业二级分类改变事件 */
    const subChange = item => {
        setSub(item);
    };

    const [searchValue] =useState({});


    const [{data ,isLoading},updateParams] = useDataApi({ initialData: [] });

    useEffect( () => {
        updateParams({ url: '/api/getAddCategroy', method: 'post', data: { project_type: cate, type: 1 ,...searchValue},initialData: []});
        setSubList([]);
    }, [searchValue,cate]);

    useEffect(()=>{
        if(!isLoading){
            if(data.length){
                setTrade(data[0].id);
                if( data[0].son.length){
                    setSub(data[0].son[0].id);
                    setSubList(data[0].son)

                }else{
                    setSubList([])
                }
            }
        }

    },[isLoading,data]);
    return (
        <div className={css.index}>

            <div className={css.boxTitle}>
                发布招采信息
            </div>
            <Form
                {...layout}
                initialValues={{remember: true}}
                scrollToFirstError={true}
                form={form}>
                <Form.Item label="选择信息类别" required className={css.row}>
                    <Radio.Group buttonStyle="solid" value={cate} onChange={cateChange} className={css.cateList}>
                        <Radio.Button value={TYPE_BIDDING}><IconFont type={cate === TYPE_BIDDING ? 'icon-selected' : 'icon-unselected'}
                                                          className={css.icons}/>招标信息</Radio.Button>
                        <Radio.Button value={TYPE_PROCUREMENT}><IconFont type={cate === TYPE_PROCUREMENT ? 'icon-selected' : 'icon-unselected'}
                                                          className={css.icons}/>采购信息</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                { cate === TYPE_BIDDING && <Fragment>
                    <Form.Item label={'选择招标类型'} required  className={css.row}>
                        <Radio.Group buttonStyle="solid" defaultValue={type} onChange={typeChange}  className={css.cateList}>
                            <Radio value={TYPE_COMMON} style={{marginLeft:'15px',color: type === TYPE_COMMON ? '#0F8605':'#333'}}>公开招标</Radio>
                              <Radio value={TYPE_INVITE} style={{marginLeft:'10px',color: type === TYPE_INVITE ? '#0F8605':'#333'}}>邀请招标</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {type === TYPE_COMMON &&
                    <Form.Item label="选择招标方式" required className={css.row}>
                        <Radio.Group buttonStyle="solid" defaultValue={mode} onChange={modeChange}  className={css.cateList}>
                            <Radio value={MODE_ON} style={{marginLeft:'15px',color: mode === MODE_ON ? '#0F8605':'#333'}}>在线电子招投标</Radio>
                            <Radio value={MODE_OFF} style={{marginLeft:'10px',color: mode === MODE_OFF ? '#0F8605':'#333'}}>仅线上发布招标公告</Radio>
                        </Radio.Group>
                    </Form.Item>
                    }
                </Fragment>
                }

                <Form.Item label="选择行业分类" required className={css.header}/>
                <div  className={css.tradeList}>
                    {data.map((item,index) =>{
                      return  <div key={'trade'+ item.id}  className={classNames(css.item,trade === item.id ? css.check:'')} onClick={() => {
                            tradeChange(item.id,item)
                        }}>
                            <img src={item.image} alt=""/>
                            <div className={css.text}>{item.name}</div>
                            <IconFont type={trade === item.id ? 'icon-check' : 'icon-unselected'} className={css.icons}/>
                        </div>
                    })}
                </div>
                <Form.Item label="选择二级分类" required style={{display: subList.length>0 ?'':'none'}}/>
                <Row  gutter={[16, 16]} className={css.subList} >
                    {subList.map((item,index)=>{
                      return  <Col span={3} key={'sub'+item.id} ><Button style={{textAlign: 'left'}} className={ sub === item.id? css.check:''} onClick={() => {subChange(item.id)}}><IconFont type={sub === item.id ? 'icon-check' : 'icon-unselected'} className={css.icons}/>{item.name}</Button></Col>
                    })}
                    </Row>
                <div className={css.btns}>
                    <Button type="primary" size={"large"} onClick={ onsubmit } ghost disabled={!sub}>
                        {cate === TYPE_BIDDING ? '创建招标项目':'创建采购项目'}
                    </Button>
                </div>
            </Form>
        </div>);
}