import React, {useEffect, useState} from 'react';
import {Breadcrumb, CardBox, DefaultList, YcLoading, YcPagination} from '../../components';
import css from './index.module.less';
import {DownLoadList} from './components';
import {useHistory} from "react-router-dom";
import {Empty} from "antd";
import {useDataApi} from "../../hook/dataApi";

export const DownloadPage = (props) => {
    const cId = getParams(props.location.search, 'c_id');
    const id = parseInt(getParams(props.location.search, 'type_id') || '') ;
    const pageSize = 25;
    const [c_id2/*, setCId*/] = useState(cId);
    const [type, setType] = useState({});
    const [page, setPage] = useState(1);

    let {push} = useHistory();
    let [{data/*, isLoading*/}, updateParams] = useDataApi();
    let [{data: hotList, isLoading: hotLoading}] = useDataApi({
        url: '/api/newsList',
        method: 'post',
        data: {c_id2, type_id:0, page: 1, flag:'hot',pagesize: 8}
    });
    let [{data: navList,isLoading:navLoading}/*, getNav*/] = useDataApi({
        url: '/api/fgetnav',
        method: 'post',
        data: {c_id: c_id2, type: type.id},
        initialData: []
    });

    useEffect(() => {
        if (!navLoading) {
            updateParams({url: '/api/newsList', method: 'post', data: {c_id2, type_id:type.id, page, pagesize: pageSize}});
        }
    }, [page, type]);

    useEffect(() => {
        if (!navLoading) {
            if(!id){
                setType({...navList[0]});
            }else{
               navList.forEach(item =>{
                   if(item.id === id){
                       setType(item);
                   }
               })
            }
        }
    }, [navLoading,id]);

    /** 分页改变事件 */
    const pageChange = (v) => {
        setPage(v);
    };

    /** 分类筛选 */
    const navChange = (value) => {
        setType(value);
    };

    /** 查看资讯详情 */
    const toViewPage = (id) => {
        push('/category/148/177/view/'+id);
    };

    /** 下载预览 */
    const toDownloadPage = (id) => {
        push({pathname: '/download/view', state: {c_id: c_id2, id,typeName: type.name,typeId:type.id}});
    };

    /** 显示更多 */
    const toMorePage = (type) => {
        if (type === 'hot') {
            push({pathname: '/category/148/178'});
        } else {
            push({pathname: '/category/148/178'});
        }
    };

    /** 获取URL参数 */
    function getParams(url, name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = url.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
    };


    return (
        <div className="container">
            <Breadcrumb list={ {name:'下载专区'} } />
            <div className={css.download}>

                <div className={css.listWrap}>
                    <div className={css.title}>
                        {
                        navList.map((opt, index) =>
                            <i onClick={() => navChange(opt)}
                               className={type.id == opt.id ? css.active : ''}
                               key={index}>{opt.name}</i>
                        )
                        }
                    </div>
                    { data.data && !data.data.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <DownLoadList items={data.data} toViewPage={toDownloadPage}/> }

                    <YcPagination style={{margin: '30px 0'}} pageSize={pageSize} total={data.count}
                                  onChange={pageChange}/>
                </div>


                <div className={css.sideBar}>
                    <CardBox title="热点新闻" more={() => {
                        toMorePage('hot')
                    }}>
                        {hotLoading ? <YcLoading/> : <DefaultList items={hotList.data} goDetail={toViewPage}/>}
                    </CardBox>
                    <div style={{marginBottom: 20}} />
                    {/*<CardBox title="项目推荐" more={() => {*/}
                    {/*    toMorePage('commend')*/}
                    {/*}}>*/}
                    {/*    {commendLoading ? <YcLoading/> :*/}
                    {/*        <DefaultList items={commendList.data} goDetail={toViewPage}/>}*/}
                    {/*</CardBox>*/}
                </div>
            </div>
        </div>
    )
}