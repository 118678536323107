import React, {Fragment, useEffect, useState} from 'react';
import {Card} from '../components';
import {YcPagination} from '../../../../components';
import classNames from 'classnames';
import {Button, Form, Input,message} from 'antd';
import {createFromIconfontCN} from '@ant-design/icons';

import {useDataApi} from '../../../../hook';
import css from './index.module.less';
import {useHistory} from "react-router-dom";
import {ExpertModal} from "../../../../common";
import {tap} from "rxjs/operators";
import {MODE_OFF} from "../../../../common/static_number";
import IconEmpty from 'aliasimgurl/icon_empty.png';

const {Search} =Input;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_tljpanwd0oh.js',
});

/** 项目评分 */
export const ProInvite = (props) => {


    const [params, setParams] = useState({page:1,type:'0',total:0,  project_name: props.location.state ? props.location.state.project_sn : ''});
    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({initialData:{data:[]}});
    const {push} = useHistory();
    useEffect(() => {
        if (!isLoading) {
            const _params = params;
            _params.total = dataSource.count;
            setParams(_params);
        }
    }, [ isLoading ]);

    useEffect(() => {
        const {project_name,type,page} =params;
        updateParams({
            url: '/api/user/openTender',
            method: 'post',
            data: {bid_type:2,page, status:type,search_name:project_name}
        })
    }, [ params ]);

    const handleSearch =(value)=>{
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };
    const handleStatusText = (opt)=>{
        switch (opt.bstatus){
            case 0:
                return <span>未邀请</span>
            case 1:
                return <span>待接受</span>
            case 2:
                return <span>已接受</span>
            case 3:
                return <span>已拒绝</span>
            case 4:
                return <span>已结束</span>
            case 5:
                return <span>已完成</span>
            default: return
        }
    };

    const onSubmit = (opt,type)=>{
        const {expert_id,project_sn} = opt;
        ExpertModal.accept({expert_id,project_sn,type}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next(res) {
                message.success('操作成功');
                window.location.reload();
            }
        });
    };
    const menu =(opt)=>{
        const {bstatus} = opt;
        if(bstatus === 1){
            return  (<Fragment>
                <Button className={classNames(css.btn,css.confirmBtn)} onClick={(e)=>{e.stopPropagation();onSubmit(opt,1) }}>接受</Button>
                <Button className={classNames(css.btn,css.rejectBtn)} onClick={(e)=>{e.stopPropagation();onSubmit(opt,2) }}>拒绝</Button>
            </Fragment>);
        }
    };
    const handleTypeChange = (value)=>{
        let _params = params;
        _params.type =value;
        setParams({..._params});
    };
    return (
        <div className={css.body}>
                <Card title="项目邀请" >
                    <div className={css.typeBox}>
                        <div className={params.type === '0'? css.active : ''} onClick={()=>handleTypeChange('0')}>全部</div>
                        <div className={params.type === '1'? css.active : ''} onClick={()=>handleTypeChange('1')}>待接受</div>
                        <div className={params.type === '2'? css.active : ''} onClick={()=>handleTypeChange('2')}>已接受</div>
                        <div className={params.type === '3'? css.active : ''} onClick={()=>handleTypeChange('3')}>已拒绝</div>
                    </div>
                    <div className={css.list}>
                    {!isLoading &&  dataSource.list.map(opt=>
                        <div className={css.item} key={opt.project_sn} onClick={()=>push({pathname:'/expert/market/view',state:{project_sn:opt.project_sn}})}>
                            <div className={classNames(css.statusBox,{1:css.awaitText,2:css.successText,3:css.rejectText,4:css.rejectText,5:css.rejectText}[opt.bstatus])}>
                                <span><IconFont type={'icon-zhuangtai'} className={css.icon}/>{handleStatusText(opt)}</span>
                                <div>评标倒计时：<span>1天23时17分21秒</span></div>
                            </div>
                            <div className={css.content}>
                                <div>项目名称：<span>{opt.project_name}</span></div>
                                <div>招标单位：<span>{opt.company_name}</span></div>
                                <div>项目金额：<span>{opt.starting_price}(万元)</span></div>
                                <div>项目编号：<span>{opt.project_sn}</span></div>
                                <div>所属行业：<span>{opt.industry_type }-{opt.industry_id}</span></div>
                                <div>招标方式：<span>{opt.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}</span></div>
                                {/*<div>招标类型：<span>{opt.tendering_type}</span></div>*/}
                                <div>评标时间：<span>{opt.evaluationstart_time} 至 {opt.evaluationend_time}</span></div>
                                <div>评标地点：<span>{opt.address}</span></div>
                                <div>抽取人数：<span>{opt.number}人</span></div>
                            </div>
                            <div className={css.operation}>
                                {menu(opt)}
                            </div>
                        </div>
                    )}
                        {
                            !isLoading &&  !dataSource.list.length && <div class={'emptyBox'}>
                                <img src={IconEmpty} alt=""/>
                                <div >暂无数据</div>
                            </div>
                        }
                    <YcPagination
                        total={ params.total || 0 }
                        pageSize={8}
                        current={ params.page }
                        onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                        style={ {margin:'30px 0'} } />
                    </div>
                </Card>
            </div>
    )
};