import React, {useState} from 'react';
import {Button, Form, Input, message} from 'antd';
import {NavLink, useHistory} from 'react-router-dom';

import {ServicesModel} from '../../../common';
import {tap} from "rxjs/operators";
import classNames from 'classnames';
import css from './index.module.less';
import loginBg from 'aliasimgurl/login_bg.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {store} from "../../../helps";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_u6htkf9z71o.js',
});

export const  Login = (props)=>{
    let { push } = useHistory();

    const [loginType, setLoginType] = useState(4);

    const onSubmit = data => {
        ServicesModel.doLogin({type:loginType,...data}).pipe(
            tap(res=>console.log(res,'pipe'))
            ).subscribe({
                next:res=>{
                   if(res.data.userinfo.status === 2) {
                       push('/expert/setting')
                    }else{
                       push('/expert/reg/0/'+loginType)
                   }
                },
                error:err=>{},
                complete:()=>{}
            });
    };

    return (
        <div className={ css.iPage }>
                <div className={ css.iForm }>
                    <div className={css.leftBg}>
                        <div className={css.title}>Welcome !</div>
                        <div className={css.subtitle}>
                            汇集全国各行业专家，致力于为客户 <br/>
                            匹配撮合专家顾问、打造本行业专属的的智囊团
                        </div>
                        <img src={loginBg} className={css.loginBg} alt=""/>
                    </div>

                    <div className={ css.iWrap }>
                        <Form onFinish={onSubmit} className={ css.formWrap }>
                            <div className={ css.formItem }>
                                <div className={ classNames(css.tapsWrap)  }>
                                    <div className={ classNames(css.tap,loginType===4?css.active:'') }
                                         onClick={ ()=>setLoginType(4) }>行业专家登录</div>
                                    <div className={ classNames(css.tap,loginType===3?css.active:'') }
                                         onClick={ ()=>setLoginType(3) }>评标专家登录</div>
                                </div>
                            </div>
                            <Form.Item  name={'username'} rules={[{required: true, message: '请输入用户名或手机号' }]} >
                                <Input size={"large"} prefix={ <IconFont type={'icon-ren'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder="请输入用户名或手机号" style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}} autoComplete="off" />
                            </Form.Item>
                            <Form.Item  name={'password'}  rules={[{required: true, message: '请输入登录密码' }]}>
                                <Input.Password size={"large"} prefix={ <IconFont type={'icon-suo'} style={{fontSize:'22px',color:'#ADB0C2'}}/> } placeholder="请输入登录密码" style={{height: '50px',background: '#F1F2F6',borderColor: '#fff',boxShadow:'none',borderRadius:'26px'}} autoComplete="off" />
                            </Form.Item>

                            <div className={ css.password} >
                                {
                                    loginType === 3 &&  <a onClick={ ()=>push({pathname:"/expert/reg/bid/index"}) }>申请成为评标专家</a>
                                }
                                {
                                    loginType === 4 &&  <a onClick={ ()=>push({pathname:"/expert/reg/industry/index"}) }>申请成为行业专家</a>
                                }
                                <NavLink className={ css['forget-password'] } to={'/expert/recover/'+loginType}>忘记密码？</NavLink>
                            </div>
                            <div className={ css.formItem }>
                                <div className={ css.btnWrap }>
                                    <div className={ css.btnInner }>
                                        <Button size="large" type="primary" htmlType="submit">登录</Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
    )
}