import React from 'react';
import css from './bai-ke-list.module.less';
import {Button} from 'antd';
import {useHistory} from "react-router-dom";

export const BaiKeList = (props) => {

    const {items=[], cId} = props;
    let { push } = useHistory();
    const goDetail = (id)=>{
        push({pathname:'/baike/view',state:{c_id:cId,id}});
    }
    return (
        <div className={css.list}>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index}>
                        <div className={css.itemInner}>
                            <div className={css.title} onClick={()=>{goDetail(opt.id)}}><span>{opt.title}</span> <span className={css.createTime}>{ opt.createtime }</span></div>
                            <div className={css.subTitle}>
                                {
                                    opt.remark.length > 80 ? opt.remark.slice(0, 80) + '...' : opt.remark
                                }
                                {
                                    opt.remark.length > 80 && <Button onClick={ ()=>{goDetail(opt.id)} } type='link'>[查看全文]</Button>
                                }
                            </div>
                            {/*<div className={css.footer}>*/}
                            {/*    <span className={css.createTime}>{ opt.createtime }</span>*/}
                            {/*    /!*<Button type='link'  className={ !opt.is_collection || css.active }><Icon type="star" theme="filled"></Icon>收藏</Button>*!/*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )
            }
        </div>
    );
}