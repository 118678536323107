import React ,{useState,useEffect}from 'react';
import {NavLink} from 'react-router-dom';
import css from './sub-nav.module.less';
import {createFromIconfontCN, UserOutlined} from '@ant-design/icons';
import {PROJECT_URL, TENDER_URL} from "../common/static_number";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_n7xfb49k5dp.js',
});


export const SubNav = (props)=>{

    const subNav = [
         {
            label:'账户设置',
            icon: <UserOutlined />,
            path:'/expert/setting'
    },{
            label:'消息通知',
            icon: <IconFont type={'icon-message'} />,
            path:'/expert/message'
        },{
        label:'项目邀请',
        icon: <IconFont type={'icon-ku'} />,
            path:'/expert/invite',
    },{
        label:'项目评分',
        path:'/expert/comment',
        icon: <IconFont type={'icon-collect'} />,
    }];

    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                    <div key={ index }>
                         <NavLink to={ opt.path } className={  css.parentNav } activeClassName={ css.selected}>
                            <div>
                               {opt.icon}&nbsp;&nbsp;&nbsp;{ opt.label }
                            </div>
                        </NavLink>

                    </div>
                )
            }
        </div>
    )
}