import React, {useState} from "react";
import css from "./auditor.module.less";
import {Button, Col, Form, Input, message, Modal, Row} from 'antd';
import {Contacts} from '../index'
import {PublishModal} from "../../../../common";
import {AUDIT_OPEN, AUDIT_SEND} from "../../../../common/static_number";
import {useHistory} from "react-router-dom";
import {store} from "../../../../helps";
import {useDataApi} from "../../../../hook";


const col={
    labelCol: { span: 6 },
    wrapperCol: { span: 17 },
};

export const Auditor = (props) => {

    const { params:{project_sn=''}} = props;
    const [visible , setVisible] = useState(false);
    const {username,user_id} = JSON.parse(store.get("userinfo")) ;
    const [users,setUsers] = useState([{username,user_id}]);
    let { push } = useHistory();
    const [{data}] = useDataApi({url: '/api/issuedDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});

    const showModal = e=>{
        setVisible(true);
    };
    const handleOk = e => {
        verify();

    };
    const verify = ()=>{
        if(users.length === 0){
            message.error('您还没有选择开标人呦~');
            return false;
        }else if( users.length === 1){
            setVisible(false);
            return true;
        }else{
            message.error('开标人只能设置一个呦~');
            return false;
        }
    };

    const handleCancel = e => {
        console.log(e);
        setVisible(false);
    };
    const submit = e=>{
          if(!verify()){
              return
          }
        PublishModal.setResponsible({type:AUDIT_OPEN,project_sn,...users[0]}).then(({data: {data, msg, code}})=>{
            if(code ===200){
                console.log("res",project_sn);
                message.success('设置成功',1, push({pathname: '/publish/listSend',state:{project_sn,step:2}}));
            }else{
                message.error(msg)
            }


        });
    };
    const checkList = e=>{
        const {title:username,id} = e[0];
        setUsers([{username,user_id:id}]);
    }
    return (<div className={css.auditor}>
        <div className={css.boxTitle}>
            设置开标人
            <div className={css.info}>
                <span >项目名称：</span>
                <span className={css.high}>{data.project_name}</span>
                <span >项目编号：</span>
                <span className={css.high}>{data.project_sn}</span>
            </div>
        </div>
        <Form
            initialValues={{ remember: true }}
            scrollToFirstError = { true}
        >
            <Row justify="center">
                <Col span={10}>
                    <Form.Item
                        {...col}
                        label="设置开标人"
                        required
                    >
                        <Input placeholder='开标人姓名'   value={users.length>0 ?users[0].username:''} disabled />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Button type={ 'primary' } onClick={()=>{showModal()}}>选择</Button>
                </Col>
            </Row>

            <div className={css.btns}>
                 <Button size={ "large"} className={css.saveBtn} onClick={submit}>
                    提交
                </Button>
               {/*: <Button type="primary" size={"large"} onClick={ nextPage } ghost>
                    下一步
                </Button>*/}
            </div>
        </Form>

        <Modal
            title="设置经办人"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Contacts success={checkList} users={users}/>
        </Modal>
    </div>);
}