import React, {useState} from 'react';
import {Input, Select} from 'antd';
import {useHistory} from "react-router-dom";

export const IndexSearch = () => {
    const { Option } = Select;
    const { Search } = Input;
    const {push} = useHistory();
    const [type ,setType] = useState('1');
    const selectChange = (v)=>{
        setType(v)
    }
    const selectBefore = (
        <Select defaultValue="1" style={{ width: 100}} onChange={selectChange}>
            <Option value="1">招标项目</Option>
            <Option value="2">采购项目</Option>
        </Select>
    );
    const handleSearch = e=>{
        if(type === '1'){
            push('/market?search_key='+e)
        }else{
            push('/bidding?search_key='+e)

        }
    };

    return (
        <Search addonBefore={selectBefore}
            placeholder="请输入关键字"
            onChange={ e=>console.log(e.target.value) }
            onSearch={handleSearch}
            enterButton="搜索" />
    );
}