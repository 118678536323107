import css from "./index.module.less";
import {Button, Descriptions, message, Modal, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDataApi, useUserInfo} from "../../../../../../hook";
import {AUDIT_PUBLISH} from "../../../../../../common/static_number";
import {PublishModal} from "../../../../../../common";
import {tap} from "rxjs/operators";

export const Marke = (props)=>{
    const {params:{project_sn=''} } = props;
    let { push } = useHistory();
    const [dataSource, setDataSource] = useState([]); //供应商列表
    const [{data}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const [userinfo] = useUserInfo();
    const [{data: supplierData}] = useDataApi({
        url: '/api/scoreList',
        method: 'post',
        data: {project_sn},
        initialData: []
    });// 获取公告中已选的供应商
    useEffect(() => {
        if(supplierData){
            supplierData.isLoading = true;
            setDataSource(supplierData);
        }
    }, [supplierData]);

    const columns = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
/*        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },*/
        {
            title: '投标状态',
            key: 'bid_status',
            dataIndex: 'bid_status',
            render: (text,record) => {
                return <span>{text}</span>
            }

        },
        {
            title: '商务得分',
            dataIndex: 'business_fraction',
            key: 'business_fraction',
            align: 'center',
            width: 100,
        },
        {
            title: '技术得分',
            dataIndex: 'technology_fraction',
            key: 'technology_fraction',
            align: 'center',
            width: 100,
        },
        {
            title: '评分',
            key: 'marker',
            dataIndex: 'marker',
            render: (text,record) => {
                const {user_id} = userinfo;
                if(supplierData.is_submit){
                    return <a onClick={()=>push({pathname:'/expert/detail/tenderDetail',state:{project_sn,user_id,type:'readonly',id:record.id}})}>查看评分</a>
                }else {
                    return <a onClick={()=>push({pathname:'/expert/detail/tenderDetail',state:{project_sn,user_id,type:'marker',id:record.id}})}>{record.technology_fraction === '' ? '去评分':'修改评分'}</a>
                }

            },
        },
    ];


    const submit = ()=>{
        Modal.confirm({
            title:'温馨提示',
            content: <div>您确定要提交当前的评标结果吗？<br/>一旦提交将不可更改</div>,
            okText:'确定',
            cancelText:'取消',
            maskClosable: true,
            onOk: ()=>{
                PublishModal.submitCommend({project_sn}).pipe(
                    tap(res=>console.log(res))
                ).subscribe({
                    next:res=>{
                        message.success('提交成功',3);
                        window.location.reload();
                    },
                    error:err=>{},
                    complete:()=>{}
                });
            },
            onCancel:()=>{

            }
        });


       // push({pathname:'/publish/listCommend',state:{project_sn,step:3}})
    }
    return (
        <div>
            {
                !dataSource.isLoading ||  <div className={css.commendScore}>
                    <div className={ css.boxTitle }>
                        评标人评分
                        <div className={css.info}>
                            <span >项目名称：</span>
                            <span className={css.high}>{data.project_name}</span>
                            <span >项目编号：</span>
                            <span className={css.high}>{data.project_sn}</span>
                        </div>
                    </div>
                    <Descriptions column={1}>
                        <Descriptions.Item label="评标时间" >{supplierData.evaluationstart_time} ~ {supplierData.evaluationend_time}</Descriptions.Item>
                        <Descriptions.Item label="供应商投标情况" />
                    </Descriptions>
                    <Table columns={columns} pagination={false} dataSource={dataSource.list} bordered />
                    <div className={css.btns}>
                        {
                            supplierData.is_submit ?  <Button type="primary" size={"large"} onClick={ ()=>push('/expert/comment')} >
                                返回个人中心
                            </Button>: <Button type="primary" size={"large"} onClick={ submit} >
                            提交
                            </Button>
                        }

                    </div>
                </div>
            }
        </div>


    )
}