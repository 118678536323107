import React, {useEffect, useState} from 'react';
import css from './apply.module.less';
import {PublishModal} from '../../../common'
import {Button, Col, Descriptions, Form, Input, message, Row} from "antd";
import BraftEditor from "braft-editor";
import {UploadFile} from "../../../components";
import {Flow} from "../../components";
import {tap} from "rxjs/operators";
import {useHistory} from 'react-router-dom';
import {useDataApi} from "../../../hook";


const layout = {
    labelCol: {span: 3},
};
const {TextArea} = Input;

export const Apply = (props) => {
    const superStep = 1;
    const [ step,setStep ] = useState(1);
    const { project_sn=''} = props.location.state;
    const [form] = Form.useForm();
    const [initData, setInitialData] = useState({});
    const [params, setParams] = useState({images: [], file: []});
    const [{data, isLoading}] = useDataApi({url: '/api/signDetail', method: 'post', data: {project_sn,type:1}});

    const superList = [
        {name: '报名', id: 2, step: 1},
        {name: '投标', id: 2, step: 2},
        {name: '开标、评标', id: 2, step: 3},
        {name: '中标结果', id: 2, step: 4},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '填写报名信息',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '查看报名结果',
            disabled: true
        },
    ];

    useEffect(() => {
        if(!isLoading){
            let _data = data;
            const {store_remark,qualifications} = data;
            _data.store_remark = BraftEditor.createEditorState(store_remark);
            _data.qualifications = qualifications || [];
            _data.isLoading = true;
            setInitialData(_data);
        }

    }, [data,isLoading]);
    const excludeControls = ['media', 'emoji'];

    let Loading = false;
    const {push} = useHistory();
    /** 提交事件 */
    const submit = (values) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }

        let _params = Object.assign({...values}, {...params});
        _params.store_remark = encodeURI(values.store_remark.toHTML());
        PublishModal.noticeApply({
            project_sn,
            ..._params,
        }).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next: (res) => {
                message.success('您的报名申请已经提交成功！', 1,()=> push({pathname: '/market/apply/result',state:{project_sn}}));
            },
            error: err => {
            },
            complete: () => {
            }
        })
    };
    return (
        <div className="container" >
             <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.apply}>
                <div className={css.boxTitle}>
                    填写报名信息
                </div>
                {
                    initData.isLoading &&  <Form
                        form={form}
                        initialValues={data}
                        scrollToFirstError={true}
                        onFinish={submit}
                        {...layout}
                    >
                        {
                            data.reject_remark && <Form.Item label={'驳回原因'} wrapperCol={20} name={'reject_remark'}>
                                <TextArea readOnly style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                            </Form.Item>
                        }
                        <Row>
                            <Col span={8} >
                                <Form.Item label="投标联系人"  name={'store_user_name'}
                                           labelCol={{span: 9}}
                                           wrapperCol={{span: 12}}
                                           rules={[{required: true, message: '请填写投标联系人'}]}>
                                    <Input placeholder="投标联系人姓名" autoComplete="off"/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="联系人电话" name={'store_user_phone'}
                                           labelCol={{offset:0,span: 9}}
                                           wrapperCol={{span: 12}}
                                           rules={[{required: true, message: '请填写投标联系人'}]}>
                                    <Input placeholder="投标联系人电话" autoComplete="off"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label='企业邮箱' name={'email'}  wrapperCol={{span: 4}} rules={[{required: true, message: '请填写企业邮箱'},{
                            type: 'email',
                            message: '邮箱格式不正确',
                        }]}>
                            <Input type="text" autoComplete="off" placeholder="企业邮箱"/>
                        </Form.Item>
                        <Form.Item
                            label={'企业资质'}
                            labelCol={{span: 3}}
                            wrapperCol={{span: 24}}
                            name={'store_remark'}
                            rules={[{required: true,message:'请填写企业资质'},({getFieldValue})=>({
                                validator(rule,value){
                                    if(!value || value.toHTML() === '<p></p>'){
                                        return Promise.reject('请填写企业资质');
                                    }
                                    return Promise.resolve();
                                }
                            })]}
                        >
                            <BraftEditor
                                excludeControls={excludeControls}
                                style={{border: '1px solid #ddd'}}
                                contentStyle={{height: '200px'}}
                            />
                        </Form.Item>

                        <Form.Item
                            name={'qualifications'}
                            wrapperCol={{offset: 3, span: 16}}
                        >
                            <UploadFile uploadNum={3} defaultFileList={params.qualifications}/>
                        </Form.Item>
                        <div className={css.btns}>
                            <Button type="primary" size={"large"} htmlType="submit" style={{marginLeft: '20px'}}
                            >
                                {initData.email?  '重新提交':'提交报名'}
                            </Button>
                        </div>
                        <Descriptions style={{marginLeft: '480px'}}>
                            <Descriptions.Item label="" >
                                温馨提示<br/>
                                1、请务必填写真实有效的企业信息。<br/>
                                2、信息提交成功后，不可修改，请您认真填写。<br/>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                }


            </div>
        </div>
    );
}