/** 静态资源路径 */
export const SERVICE_PACTION = `<p style="text-align: center;">
    <span style="font-size: 24px; color: rgb(255, 192, 0); font-family: 宋体, SimSun;">《服务协议》</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第一条 服务条款的确认与接纳</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）以下所述条款和条件即构成您与昆明农村产权流转服务平台（以下简称“本平台”，域名为kmnccq.com）就使用服务所达成的协议。当您完成注册并在服务条款前方囗打√时，即表示您已同意受本平台服务协议约束，包括但不限于本协议本平台会员服务协议和隐私权政策等。如果您不同意接受全部的条款和条件，请不要在口打√，同时您将无权使用本平台的服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）本平台有权随时修改本协议条款和条件，并只需公示于其网站，而无需征得您的事先同意。修改后的条款应于公示即时生效。在本平台修改服务条款后，您继续使用服务应被视作您已接受了修改后的条款。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（三）除非得到本平台的书面授权，任何人将不得修改本协议。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（四）本平台提供的网络服务的所有权和最终解释权归本平台所有。本协议中，被许可使用本平台服务的用户均简称为“用户”。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第二条 服务内容</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）在用户同意遵守本协议条款前提下，用户有权在本平台网站指定位置上发布符合中国各项法律法规及规范性文件以及本平台网站服务条款要求的信息。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）本平台运用自己的操作系统通过国际互联网络为用户顺利实现在本平台网站的信息发布提供服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第三条 用户权利义务</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）用户有权在本平台网站指定位置上发布符合中国各项法律法规及规范性文件以及本平台网站服务条款要求的信息，并依据本平台服务规则享受本平台提供的各项服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）用户有义务保证其发布的信息符合法律、法规及本平台网站的相关服务规则，并及时对已发布的信息进行维护和管理。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（三）考虑到本平台产品服务的重要性，用户有义务保证其提供的所有信息（包括但不限于个人注册信息、发布的项目信息等）真实、准确，无重大遗漏或误导。如因用户提供的信息不准确、不真实、不合法有效，本平台保留结束用户使用本平台各项服务的权利，同时用户应赔偿因此造成的一切损失。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（四）除本平台规定的条件外，用户因其在本平台网站的信息发布行为而达成的交易，应当与交易对方约定具体交易条件并完成交易。同时，用户明确知道，自己应当对本平台上发布的项目做出独立的投资判断，本平台不对用户的投资判断承担任何责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第四条 本平台权利义务</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）本平台应运用专业电子技术为用户发布信息提供技术服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）如用户有如下行为之一的，本平台有权立即停止用户所发布的信息的技术支持，并保存有关记录，必要时可依据法律法规向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。用户应承担全部法律责任，并赔偿本平台由此受到的损失，包括合理的追索费用： 1、用户提供的产品有侵害任何第三方知识产权、财产权等合法权益，或可能对第三方造成不利影响或侵害； 2、用户利用本平台提供之网络平台进行任何犯罪活动，或宣传散播法律法规禁止散播的信息，或一切损害本平台利益的活动； 3、用户有违反本协议任何条款的行为，经本平台警告后仍未更正的； 4、用户的行为干扰本平台的服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（三）用户同意本平台有权依据本协议及本平台服务规则对用户的不合法、不合规行为采取行动，包括但不限于中断其帐号、删除地址、目录或关闭服务器等。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（四）本平台对发布的信息真实性、及时性和有效性不做任何形式的担保，对该等信息的理解和理应应当依赖于用户自身判断。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第五条 免责及责任的限制与排除</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）根据本协议，本平台仅为用户提供网络空间及技术服务，对用户发布的信息的真实性、合法性、及时性和有效性不作任何性质的担保及保证，用户理解并使用这些信息应当依赖自身的独立思考和投资判断。本平台对用户的投资收益不做任何承诺，同时对投资损失不承担任何责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）本平台尽力维护本平台网站的稳定，但不就电讯系统或互联网的中断或无法运作、技术故障、计算机错误或病毒、信息损坏或丢失或由此而产生的其他任何性质的破坏而向用户或任何第三方承担赔偿责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（三）用户明确同意使用本平台服务的风险由用户个人承担。本平台不担保服务一定能满足用户的要求，用户理解并接受下载或通过本平台产品服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（四）各方承认本条款反映了各方就协商谈判达成的一致意见。各方均完全了解本条款的后果并进一步承认本条款的合理性。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">第六条 知识产权声明</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）本平台拥有平台网站内所有信息内容（包括但不限于文字、图片、软件、音频、视频）的版权。任何被授权的浏览、复制、打印和传播属于平台网站内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）本平台所有的产品、技术与所有程序均属于昆明农村产权流转服务平台知识产权。未经本平台许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，本平台将依法追究法律责任。</span>
</p>
<p>
    <br/>
</p>`;
export const PRIVACY_PACTION = `<p style="text-align: center;">
    <span style="font-size: 24px; color: rgb(255, 192, 0); font-family: 宋体, SimSun;">《隐私保护声明》</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">欢迎您访问昆明农村产权流转服务平台！昆明农村产权流转服务平台（以下简称“本平台”），域名为kmnccq.com。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本平台网站网络服务隐私权保护声明系本平台对于保护您（以下简称“您”）个人隐私之承诺。鉴于互联网的特性，本网站网络服务（以下简称“网络服务”）在运营过程中，将无可避免地与您发生直接或间接的互动关系，故特此说明本平台对您个人信息所采取的收集、使用和保护政策，请您务必仔细阅读。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">1. 使用者非个人化信息</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本平台将通过您的IP地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，本平台亦进行客流量统计，从而改进网络服务和网站的管理和服务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">2. 个人资料</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">2.1 通常，您必须在注册之后才能登录访问网络服务某些栏目并获取信息。在请求您提供有关信息之前，本平台会解释这些信息的用途。在通常情况下，这类注册只要求您提供一个电子邮件地址或手机号码和一些诸如您的姓名一类的基本信息。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">2.2 网络服务的某些功能可能需要您提供更多的信息。本平台这样做是为了更好的理解您的需求，以便向您提供有效的服务。本平台收集的信息包括姓名，地址和电话号码等。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">2.3 在不透露单个您隐私资料的前提下，本平台有权对整个您数据库进行分析并对您数据库进行商业上的利用。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">3. 信息安全</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">3.1 本平台将对您所提供的资料进行严格的管理及保护，本平台将使用相应的技术，防止您的个人资料丢失、被盗用或遭篡改。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">3.2 本平台在必要时委托专业技术人员代为对该类资料进行电脑处理，以符合专业分工时代的需求。如本平台将电脑处理之通知送达予您，而您未在通知规定的时间内主动明示反对，本平台将推定您已同意。惟在其后您仍然有权如下述第4.1.(d)条之规定，请求停止电脑处理。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">4. 您的权利</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">4.1 您对于自己的个人资料享有以下权利： (a) 随时查询及请求阅览； (b) 随时请求补充或更正； (c) 随时请求删除； (d) 请求停止电脑处理及利用。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">4.2 针对以上权利，本平台为您提供相关服务，您可以及时联系本平台。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">5、未成年人隐私权的保护</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">18岁以下的未成年人参加本网站的活动须事先得到家长或监护人（以下统称为&quot;监护人&quot;）的同意方可获得本网站的服务。监护人有取消被监护人使用本网站服务的权力。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">6、用户使用准则</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户对自己发表在本网站上的文字、信息、数据、图片、图表、照片、软件、声音、影像或其他任何形式的资料（不论是公开发布或私下传递），都要负全部责任。为此，用户必须遵守以下准则：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(1) 不在本网站上发布、传播违反中华人民共和国法律、法规的任何资料和信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(2) 不在本网站上发布、传播或使用侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的资料和信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(3) 不在本网站上发布、传播、使用或传输侵犯他人商标权、著作权、专利权、专有技术、商业秘密或其他财产权利的资料和信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(4) 不在本网站上发布、传播或传输可能导致电脑软件、硬件或通讯设备的功能发生中断、破坏或被限制的带有电脑病毒或其他电脑密码的电子信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(5) 不在本网站上发布、传播或传输任何非法的、骚扰性的、中伤他人的、侮辱性的、恐吓性的、伤害性的、低俗的或淫秽的信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(6) 不在本网站上发布、传播或传输教唆他人犯罪或涉及国家安全的信息。 用户违反上述准则，本网站不须告之对方即可取消其使用的权力。用户要对自己在网上的行为承担法律责任。若在本网站上散布和传播反动、色情或其他违反国家法律的信息，本网站上的系统记录可作为其违法的证据提供给有关部门处理。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">7. 限制利用原则</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本平台惟在符合下列条件之一，方对收集之个人资料进行上述规定范围以外之利用：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(a) 已取得您的书面同意；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(b) 为免除您在生命、身体或财产方面之急迫危险；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(c) 为防止他人权益之重大危害；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(d) 为增进公共利益，且无害于您的重大利益。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">8. 个人资料之披露</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">当司法机关、政府机关依照法定程序要求本平台披露个人资料时，本平台将根据司法、执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本平台均得免责。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">9. 信息传送</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">9.1 本平台及其必要的服务伙伴使用您的个人资料来运作网络服务，并且会通知您各项新的功能与服务，以及本平台及其附属公司的各类产品。本平台也会谨慎地挑选来自其他公司的产品或服务资料传送给您，通常是有关于网络服务和本平台本身的服务，但并非必要（次要用途）。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">9.2 如果本平台想要将个人资料用在次要用途上，本平台会提供您如何拒绝这项服务的说明。您可以通过本平台寄给您的资料或促销信件上的说明，终止这些信件的发送。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">10、网络服务退订声明</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">您可根据个人意愿，随时提出退订网络服务，可通过邮件，电话等方式与本网站联系，本网站会将您注册信息全部删除。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">11. 免责</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">除上述第8条规定属免责外，出现下列情况时本平台亦毋需承担任何责任：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(a) 由于您将您密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露以及导致所形成的一切后果；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(b) 任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络服务和网站正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">(c) 由于与网络服务链接的其它信息网络服务所造成之个人资料泄露及由此而导致的任何法律争议和后果。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">12. 解释权</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本平台网站网络服务隐私权保护声明的修改及解释权均属于本平台。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户违反上述准则，本网站不须告之对方即可取消其使用的权力。用户要对自己在网上的行为承担法律责任。若在本网站上散布和传播反动、色情或其他违反国家法律的信息，本网站上的系统记录可作为其违法的证据提供给有关部门处理。</span>
</p>`;

export const EXPERT_PACTION = `<p style=";text-align:center">
    <span style="font-family: &quot;Segoe UI&quot;; letter-spacing: 0px; font-size: 24px; background: rgb(255, 255, 255); color: rgb(255, 192, 0);">《专家注册须知<span style="color: rgb(255, 192, 0); font-family: &quot;Segoe UI&quot;; font-size: 24px; text-align: center; background-color: rgb(255, 255, 255);">》</span></span>
</p>
<p></p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册评标专家应具备以下条件：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">具有较高的业务素质和良好的职业道德，在项目评标过程中能以客观公正、廉洁自律、遵纪守法为行为准则；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">从事相关领域工作满8年，具有本科（含本科）以上文化程度，高级专业技术职称或者具有同等专业水平，精通专业业务，熟悉产品情况，在其专业领域享有一定声誉；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">熟悉政府采购、招标投标的相关政策法规和业务理论知识，能胜任评标工作；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">承诺以独立身份参加评标工作，并接受财政部门的监督管理；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">不满70周岁，身体健康，能够承担评审工作；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">申请成为评审专家前三年内，没有违纪违法等不良行为记录；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">财政部门要求的其他条件。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">申请评标专家应填报或扫描录入以下资料：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">学历及专业资格证书；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">身份证等证明本人身份的有效证件；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">个人研究或工作成就（包括学术论文、科研成果、发明创造等）；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本人所在单位或行业组织、其他评审专家的评荐意见；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">同级财政部门要求的其他资料。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册评审专家享有以下权利：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对政府采购制度及相关情况的知情权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对政府采购项目的独立评审权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对中标（成交）候选供应商的表决权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">获得相应的评审、咨询和论证劳务报酬；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">参加财政部门组织的各类政府采购业务培训；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">法律、法规和规章规定的其他权利。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册评审专家承担以下义务：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">应邀并准时参加政府采购项目的评审、咨询和论证工作。遇特殊情况不能出席或因故不能按时参加评审、咨询和论证时，应及时告知财政部门和采购组织机构，不得私下转托他人参加；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">在项目评审、咨询和论证过程中，发现有与本人存在利害关系的供应商参与采购活动时，应主动提出回避；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">自觉遵守政府采购廉洁自律规定，不得接受政府采购供应商及其他相关当事人的宴请、财物或者有价证券等其他好处；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">严格遵守政府采购工作纪律，不得对外透露方案咨询、论证情况以及与采购项目有关的评审情况及其他信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">客观、公正地履行评审职责，自觉遵守职业道德，客观、独立地出具评审意见或咨询论证意见，并对所出具意见承担个人责任；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">评审时如发现供应商有不正当竞争或串通等违规行为的，应当向采购组织机构或财政部门报告，并在评审意见中予以特别说明；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">配合采购组织机构和财政部门处理供应商的质疑、投诉等事宜，接受并解答采购单位或有关政府采购监管部门对评审活动或其评审意见的咨询或质疑；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">法律、法规和规章规定的其他义务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">其他事项：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">请仔细阅读下列《评标专家注册协议》。阅读协议的过程中，如果不同意协议中任何条款约定的，评审专家应立即停止注册程序。</span>
</p>
<p>
    <span style="letter-spacing: 0px; font-size: 14px;"><span style="font-family:宋体, SimSun"><br/></span><br/></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;text-indent:0;padding:0 0 0 0 ;text-align:center;line-height:20px;background:rgb(255,255,255)">
    <span style="font-family: &quot;Segoe UI&quot;; letter-spacing: 0px; font-size: 24px; color: rgb(255, 192, 0);">《专家注册协议<span style="letter-spacing: 0px; font-family: &quot;Segoe UI&quot;; font-size: 24px; text-align: center; background-color: rgb(255, 255, 255);">》</span></span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">根据《中华人民共和国政府采购法》、《中华人民共和国招标投标法》、《全国人大常委会关于维护互联网安全的决定》、《互联网信息服务管理办法》、《评标专家管理办法》等有关法律法规，制订以下条款：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">一、接受条款</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">专家智库系统平台（以下简称专家智库平台）服务由昆明农村产权流转服务平台提供。&nbsp;专家智库平台在此特别提醒用户，本协议内容包括协议正文及所有财政部门和专家智库平台已经发布或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户在申请注册流程中点击同意本协议之前，请务必审慎阅读、充分理解本协议各条款内容，特别是&nbsp;免除或者限制责任的条款、法律适用和争议解决条款，以及其他以粗体下划线标识的涉及用户核心利益的重要条款。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">当用户按照注册页面提示填写信息、阅读并同意本&nbsp;协议且完成全部注册程序后，即表示用户已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果用户不同意本协议或其中任何条款约定，用户应立即停止注册程序。另外，用户通过点击平台其它相关位置上的同意按钮，亦表示完全接受了该按钮相关文件中的全部内容。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">二、账户注册与使用</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册评标专家应具备以下条件：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（1）具有较高的业务素质和良好的职业道德，在政府采购的评审过程中能以客观公正、廉洁自律、遵纪守法为行为准则。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（2）从事相关领域工作满8年，具有本科（含本科）以上文化程度，高级专业技术职称或者具有同等专业水平，精通专业业务，熟悉产品情况，在其专业领域享有一定声誉。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（3）熟悉政府采购、招标投标的相关政策法规和业务理论知识，能胜任评标工作。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（4）承诺以独立身份参加评标工作，并接受财政部门的监督管理。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（5）不满70周岁，身体健康，能够承担评审工作</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（6）申请成为评审专家前三年内，没有违纪违法等不良行为记录；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（7）财政部门要求的其他条件。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册（登记）信息：用户应当向专家智库平台提供真实准确的注册（登记）信息，包括但不限于姓名、性别、身份证号码、住所、联系地址、联系电话、电子邮箱、工作单位、毕业院校等，以便专家智库平台&nbsp;或其他用户通过上述联系方式与用户进行联系，同时便于财政部门进行符合性审查。登记信息将通过平台向社会公开，接受社会监督。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">注册（登记）信息发生变化的，用户应及时更新，确保信息最新、真实、准确、完整、有效。财政部门或其委托机构将依法不时地对用户的信息进行检查核实。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">如因提供虚假登记信息、联系方式不确切或不及时告知变更后的联系方式，用户应自行承担由此可能对其自身、他人及/或专家智库平台产生的全部法律后果。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">账户获得</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（1）当用户按照注册页面提示填写信息且完成全部注册程序后，用户可获得专家智库平台账户并成为专家智库平台用户。同一评审专家在专家智库平台上只能注册一个有效的账户。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（2）注册评标专家还需通过财政部门审查。&nbsp;评审专家应通过专家智库平台填报并扫描录入以下材料：a、学历及专业资格证书；b、身份证等证明本人身份的有效证件；c、个人研究或工作成就（包括学术论文、科研成果、发明创造等）；d、本人所在单位或行业组织、其他评审专家的评荐意见；e、同级财政部门要求提供的其他材料。&nbsp;通过财政部门审查的专家，即成为正式专家，经网上公示且无异议后入选评审专家库。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">账户安全与管理</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（1）请用户务必保管好账户及其密码，专家智库平台任何时候均不会主动要求用户提供账户密码。若因用户保管不善导致用户账户信息泄露、采购项目评审信息泄露，或遭受他人攻击、诈骗等，从而引起的所有损失及不利后果，由用户自行承担全部责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（2）用户进一步确认并同意，除本协议另有规定外，在用户账户登录状态下进行的所有操作均视为用户本人的操作，用户应对其账户项下的所有行为结果（包括但不限于在线评审、咨询、论证）负责，专家智库平台不承担任何责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">三、评审专家的权利和义务</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）评审专家的权利</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对政府采购制度及相关情况的知情权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对政府采购项目的独立评审权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对中标（成交）候选供应商的表决权；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">获得相应的评审、咨询和论证劳务报酬；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">参加财政部门组织的各类政府采购业务培训；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">法律、法规和规章规定的其他权利。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）评审专家的义务</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">应邀并准时参加政府采购项目的评审、咨询和论证工作。遇特殊情况不能出席或因故不能按时参加评审、咨询和论证时，应及时告知财政部门和采购组织机构，不得私下转托他人参加；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">在项目评审、咨询和论证过程中，发现有与本人存在利害关系的供应商参与采购活动时，应主动提出回避；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">自觉遵守政府采购廉洁自律规定，不得接受政府采购供应商及其他相关当事人的宴请、财物或者有价证券等其他好处；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">严格遵守政府采购工作纪律，不得对外透露方案咨询、论证情况以及与采购项目有关的评审情况及其他信息；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">客观、公正地履行评审职责，自觉遵守职业道德，客观、独立地出具评审意见或咨询论证意见，并对所出具意见承担个人责任；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">评审时如发现供应商有不正当竞争或串通等违规行为的，应当向采购组织机构或财政部门报告，并在评审意见中予以特别说明；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">配合采购组织机构和财政部门处理供应商的质疑、投诉等事宜，接受并解答采购单位或有关政府采购监管部门对评审活动或其评审意见的咨询或质疑；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">法律、法规和规章规定的其他义务。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">四、用户信息的保护及授权</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（一）个人信息的保护</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">专家智库平台非常重视对用户个人信息的保护，在用户使用专家智库平台各项服务时，用户同意专家智库平台按照《隐私声明》及/或财政部门的要求收集、存储、使用、披露和保护用户的个人信息。隐私声明是本协议不可分割的组成部分。因此，请用户仔细阅读。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（二）非个人信息的保证与授权</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户保证对其所发布的信息拥有相应、合法的权利，不得发布含有《互联网信息服务管理办法》第十五条规定的信息。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对于用户提供及发布的除个人信息外的文字、图片、视频、音频等非个人信息，用户免费授予专家智库平台一项永久、全球排他的许可使用权利及再授权给其他第三方使用的权利。用户同意专家智库平台存储、使用、复制、修订、编辑、发布、展示、翻译、分发用户的非个人信息或通过各种形式制作、使用其派生作品。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">五、责任限制及免除</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">对于下述原因导致专家智库平台无法正常提供服务的，专家智库平台不承担任何责任：</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（1）因自然灾害、罢工、暴乱、战争等不可抗力因素；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（2）因电力供应故障、通讯网络故障等公共服务因素或专家智库平台升级、维护因素；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（3）因黑客、病毒、木马、恶意程序攻击、常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全，以及第三方服务瑕疵或政府行为、司法行政命令等因素；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（4）在紧急情况下为维护国家安全、其他用户及/或第三方之权益等因素；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（5）用户操作不当或用户的上网设备软硬件出现故障等非专家智库平台因素；</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">（6）财政部门作出有关限制操作。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">尽管有前款约定，专家智库平台将采取合理行动积极促使服务恢复正常。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">专家智库平台以事先通知方式修改、中断或终止全部或部分本平台所提供的服务，若由此给用户造成损失的，专家智库平台对该等损失不承担任何责任。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">除法律另有规定外，在任何情况下专家智库平台不对使用本平台服务或功能导致的任何特殊、附带、偶然或间接的损害进行赔偿，即使专家智库平台已被告知可能发生该等损害。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">六、违约责任</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户存在违反适用法律、法规或本协议项下的行为的，专家智库平台有权依据该行为性质，无需事先通知用户即可采取包括但不限于中断服务、限制使用、终止服务等措施；涉及政府采购监管权限的，专家智库平台将提请财政部门确认，并可建议财政部门对其进行处理、处罚。用户同意承担由此给专家智库平台或第三方造成的全部损失。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">七、协议的修改</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">专家智库平台可根据国家法律法规变化及维护交易秩序等需要，不时修改本协议、专家智库平台规则，变更后的协议、规则（以下简称变更事项）一旦在域名为kmnccq.com对应的网站或各级政府采购相关网站上公布即生效，并代替原版的协议、规则。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户有义务不时关注并阅读最新版的协议、规则。如用户对已生效的变更事项不同意的，用户应当于变更事项确定的生效之日起停止使用专家智库平台服务，变更事项对用户不产生效力；如用户在变更事项生效后仍继续使用专家智库平台服务，则视为用户同意已生效的变更事项。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">八、知识产权及保密条款</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户知晓并同意，除用户依法发布的用户信息外，专家智库平台所有系统及专家智库平台上所有内容，包括但不限于著作、商标、LOGO、域名、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计以及任何有关的衍生作品等，均由专家智库平台或相关权利人依法拥有其相关的著作权、商标权、专利权、域名权、商业秘密及其他合法权利。未经专家智库平台书面同意，用户不得擅自使用。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户应对其在使用本平台服务过程中所知悉的属于专家智库平台或其他用户（包括但不限于采购人、供应商、代理机构等）的保密或专有的信息予以保密，保密期限自用户接收或知悉专家智库平台或其他用户保密信息之日起至该等保密信息公开之日止，但用户与专家智库平台其他用户另有约定的除外。如用户违反保密义务造成专家智库平台或其他用户损失的，用户应自行承担全部赔偿责任；若由此导致其他用户向专家智库平台主张权益、诉讼或索赔的，用户应配合专家智库平台解决争议，并赔偿专家智库平台因此产生的全部损失。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">九、法律适用及争议解决</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">本协议之订立、解释、修订、补充、执行与争议解决，均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">用户因使用专家智库平台服务所产生的或与专家智库平台服务有关的争议，由专家智库平台与用户协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun;">十、其他</span>
</p>
<p>
    <span style="font-size: 14px; font-family: 宋体, SimSun; color: rgb(255, 0, 0);">通知：用户同意专家智库平台通过下列任一种方式向用户发送通知：（1）公告；（2）站内信、客户端推送的消息；（3）根据用户预留的联系方式发送的语音电话、短信、电子邮件、函件等。</span><span style="font-family: 宋体, SimSun; font-size: 14px;">专家智库平台以前述约定的电子方式发出的书面通知，在发送成功后即视为送达；以纸质载体发出的函件等书面通知，在专家智库平台交邮后的第五个自然日即视为送达。</span>
</p>
<p>
    <span style="font-family: 宋体, SimSun; font-size: 14px;">本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</span>
</p>
<p>
    <span style="font-family: 宋体, SimSun; font-size: 14px;">在法律允许的最大范围内，专家智库平台对本协议拥有解释权和修改权。</span>
</p>
<p>
    <span style="font-family: 宋体, SimSun; font-size: 14px;">其他相关事项请阅读《中华人民共和国政府采购法》、《中华人民共和国招标投标法》、《评标专家管理办法》。</span>
</p>
<p>
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
`;