import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import demoimg from 'aliasimgurl/img_notices_demo.png';
import {Typography} from 'antd';
import css from './notices-list.module.less';
import {resourcesUrl} from "../../../common/static_number";

export const NoticesList =(props)=>{

    const {items=[],goDetail} = props;
    const [after,setAfter] = useState([]);
    const [before,setBefore] = useState([]);
    useEffect(()=>{
        console.log("items",items);
        if(items.length){
            let [_before,_after] = R.splitAt(2,items);
            setAfter([..._after]);
            setBefore([..._before]);
        }

    },[items]);

    const onError = ()=>{
        let _r = before.map(opt=>({...opt,images:demoimg}));
        setBefore(_r);
    };

    const [ w,setWidth] = useState(590);

    const changeWh = (v)=>{
        if (v) {
            let _w = v.clientWidth;
            setWidth(parseInt(_w,10));
            // console.log(_w)
        }
    };

    return (
        <div className={ css.list }>
            <div className={ css.before }>
                {
                    before.map((o,i)=>
                        <div ref={ _div=>changeWh(_div) } className={ css.item } key={ i } style={ {width:w} } onClick={()=>goDetail(o)}>
                            <div className={ css.imgWrap } >
                                <div className={ css.imgInnerWarp }>
                                    <img className={ css.img } style={ { height:92,width:136 } }
                                        onError={ (e)=>onError(e) } src={resourcesUrl+ o.images[0].file } alt=""/>
                                    <div className={ css.extText }>精选</div>
                                </div>
                            </div>
                            <div className={css.descwrap}>
                                <div className={ css.title } >
                                    <Typography.Paragraph ellipsis={ {rows:2} } style={ {marginBottom:0} } >{ o.title }</Typography.Paragraph>
                                </div>
                                <div className={ css.desc } >
                                    <Typography.Paragraph ellipsis={ {rows:3} } style={ {marginBottom:0} } ><div dangerouslySetInnerHTML={{__html:o.remark}}/></Typography.Paragraph>
                                </div>
                            </div>

                        </div>
                    )
                }
            </div>
            <div className={ css.after }>
                {
                    after.map((opt,index)=>
                        <div className={ classNames(css.item,index>11?css.noLine:'') } key={ index } style={ {width:w} } onClick={()=>goDetail(opt)}>
                            <div className={ css.title }>{ opt.title }</div>
                            <div className={ css.time }>{ opt.createtime }</div>
                        </div>
                    )
                }
            </div>

        </div>
    );
}